<template>
	<div>
		<!-- 后台报表 -->
		<div class="queryBox mb10">
			<el-button size="mini" type="primary" icon="el-icon-plus" @click="addAccount('new')">{{$t('main.accountMenu.add')}}</el-button>
			
				<!-- <el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button> -->
				<el-input size="mini" placeholder="请输入商户号" v-model="check_business_no" clearable class="mr20 ml40" style="width: 200px"></el-input>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
			<el-button size="mini" type="primary" icon="el-icon-document"  @click="getExcel" :disabled="!tableData.length">导出商户列表</el-button>
			
		</div>
		<div class="queryBox mb10 center flexX flexcenter" style="width: 80%;">
			<div class="ml10">
				<el-button size="mini" type="success" plain @click="showAllLog()">额度变动日志</el-button>
			</div>
			<div class="ml10">
				<el-button size="mini" type="success" plain @click="openFeeDrawer()">一键配置费率</el-button>
			</div>
			<div class="ml60">
				<template>
					<el-checkbox-group size="mini" v-model="TypeCheckList" @change="handleCheckedTypeChange">
						<el-checkbox-button v-for="item,index in typeList" :label="item.id" :key="index">{{item.name}}</el-checkbox-button>
					</el-checkbox-group>
				</template>
			</div>
		</div>
		<div>
			<el-table :max-height="height" :height="height" v-loading="loading" stripe :data="tableData" border :indent="40" row-key="id" size="mini">
				<el-table-column fixed label="商户id" width="80px">
					<template slot-scope="scope">
						<span class="mr10">{{scope.row.business_no}}</span>
						<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.business_no)"></i>
					</template>
				</el-table-column>
				
				<el-table-column fixed label="商户名称" width="150px" show-overflow-tooltip>
					<template slot-scope="scope">
						<span >{{scope.row.business_name}}</span>
					</template>
				</el-table-column>
				<el-table-column fixed label="登录账号" width="150px" show-overflow-tooltip>
					<template slot-scope="scope">
						账号：<span class="mr10">{{scope.row.username}}</span><i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.username)"></i><br/>
						谷歌：<span v-if="scope.row.is_bind_google==1"><el-link :underline="false" type="success" class="mr10">已绑定</el-link><el-link type="primary"  @click="setMemberGoogleKey(scope.row)">解绑</el-link></span>
						<span v-if="scope.row.is_bind_google!=1">未绑定</span>
					</template>
				</el-table-column>
				<el-table-column label="余额" width="160px" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span>{{scope.row.money | tofixed_two}}</span>
							<span><el-link class="mr10" type="primary" @click="addAccount('unFreeze',scope.row)">调额</el-link></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="预付" width="195px" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span >{{scope.row.money_repaid | tofixed_two}}</span>
							<span>
								<el-link class="mr10" type="primary" @click="jiesuan(scope.row)">结算</el-link>
								<el-link class="mr10" type="primary" @click="addAccount('yufu',scope.row)">调预付</el-link>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="净预付" width="180px" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span >{{scope.row.remaindRepaid | tofixed_two}}</span>
							<span>
								<el-link class="mr10" type="primary" @click="jsmx(scope.row)">明细</el-link>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商户状态" width="130px" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch class="mr10"
						  v-model="scope.row.stateA"
						  active-color="#13ce66"
						  inactive-color="#ff4949"
						  @change="changeState(scope.row)"
						  :width="30"
						  active-text="启用"
						  inactive-text="停用"
						  >
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="备注" show-overflow-tooltip>
					<template slot-scope="scope">
						<span class="yctable">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope" class="flexX flexBetween">
						<el-link type="primary" @click="addAccount('edt',scope.row)" class="mr10">编辑</el-link>
						<el-link type="primary" @click="openDrawer(scope.row)" class="mr10">配置</el-link>
						<el-link type="primary" @click="resPassword(scope.row)" class="mr10">重置密码</el-link>
						<el-link type="primary" @click="addAccount('whitelist',scope.row)" class="mr10">白名单</el-link>
						<!-- <el-link type="primary" @click="addAccount('payPassword',scope.row)" class="mr10">重置代付密码</el-link> -->
						<el-link type="primary" @click="openGateway('gateway',scope.row)" class="mr10">接入</el-link>
						<el-popconfirm
						  confirm-button-text='确定'
						  cancel-button-text='取消'
						  icon="el-icon-info"
						  icon-color="red"
						  title="确定删除商户吗？"
						  @confirm="delTrader(scope.row.id)"
						>
						  <el-link type="primary" size="mini" slot="reference">删除</el-link>
						</el-popconfirm>
						
						<!-- <el-link type="primary" @click="addAccount('payMatchType',item)" class="mr10">{{$t('main.accountMenu.nameMatchingPattern')}}</el-link> -->
					</template>
				</el-table-column>
			</el-table>
			
		</div>
		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<!-- ------------------------------------ 抽屉  START  ----------------------------------- -->
		
		<div>
			<el-drawer
			  title=""
			  :visible.sync="dwdialog"
			  direction="rtl"
			  custom-class="demo-drawer"
			  ref="drawer"
			  :with-header="false"
			  size='50%'
			  :destroy-on-close="true"
			  :before-close='handleClose'
			  :show-close="true"
			  >
			  <div v-loading="" class="demo-drawer__content">
				  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				    <el-menu-item index="fee">代收费率配置</el-menu-item>
				    <el-menu-item index="reFee">代付费率配置</el-menu-item>
				    <!-- <el-menu-item index="offlineFee">线下入金手续费</el-menu-item> -->
			    	<el-menu-item index="payPower">代收通道配置</el-menu-item>
			    	<el-menu-item index="ratio">代收通道分流</el-menu-item>
					<el-menu-item index="repayPower">代付通道配置</el-menu-item>
					<el-menu-item index="repayRatio">代付通道分流</el-menu-item>
				  </el-menu>
				  <!-- 代收费率 -->
				  <el-form  v-if="activeIndex === 'fee'" :label-position="'left'" label-width="100px" class="mb20">
				  	<el-table v-loading='dwloading' :data="traderFeeDataArr" :key='Math.round((Math.random()*1000))'  v-if="!traderFeeLoading" border size="mini" stripe  height="550px">
				  		<el-table-column label="代收方式" prop="pay_type_name" align="center" width="150px">
				  			<template slot-scope="scope">
				  				【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}
				  			</template>
				  		</el-table-column>
				  
				  		<el-table-column label="费率(‰)" align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 7.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  					<div>代理：{{scope.row.agent_fee}} </div>
				  				</div>
				  			</template>
				  		</el-table-column>
				  		<el-table-column label="限额" width="" align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
									<!-- <div style="width: 5rem">通道：</div> -->
				  					<el-input class="mr10" size="mini" v-model="scope.row.min_gold" placeholder="请输入内容"></el-input>
				  					<el-input size="mini" v-model="scope.row.max_gold" placeholder="请输入内容"></el-input>
				  				</div>
				  			</template>
				  		</el-table-column>
				  	</el-table>
					
				  </el-form>
				  
				  <!-- 代付费率 -->
				  
				  <el-form  v-if="activeIndex === 'reFee'" :label-position="'left'" label-width="100px" class="mb20">
				  	<el-table  v-loading='dwloading' :data="traderRepayFeeData" :key='Math.round((Math.random()*1000))'  v-if="!traderFeeLoading" border size="mini" stripe  height="550px">
				  		<el-table-column label="代付方式" prop="pay_type_name" align="center" width="120px">
				  			<template slot-scope="scope">
				  				【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}
				  			</template>
				  		</el-table-column>
				  
				  		<el-table-column label="费率(‰)" align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 6.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  					<div>代理：{{scope.row.agent_fee}} </div>
				  				</div>
				  			</template>
				  		</el-table-column>
				  		<el-table-column label="单笔(元)" width="" align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
				  					<el-input class="mr10" style="width: 4.5rem;" size="mini" v-model="scope.row.fee_num" placeholder="请输入内容"></el-input>
				  					<span class="ml20">代理：{{scope.row.agent_fee_num | tofixed_two}} </span>
				  				</div>
				  			</template>
				  		</el-table-column>
				  		<el-table-column label="限额" width="" align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
				  					<el-input class="mr10" size="mini" v-model="scope.row.min_gold" placeholder="请输入内容"></el-input>
				  					<el-input size="mini" v-model="scope.row.max_gold" placeholder="请输入内容"></el-input>
				  				</div>
				  			</template>
				  		</el-table-column>
				  	</el-table>
				  					
				  </el-form>
				  
				  
				  <!-- 代收通道配置 -->
				  <el-form v-if="activeIndex === 'payPower'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<div>
				  		<el-table v-loading='dwloading' height="550px" :data="newPayWay" border size="mini" stripe  :span-method="objectSpanMethod">
				  			<el-table-column prop="name" label="通道" width="200px" align="center">
				  				<template slot-scope="scope">
				  					<el-checkbox v-model="scope.row.state" @change="payTypeChange(scope.row)">
				  						{{scope.row.name}} </el-checkbox>
				  				</template>
				  			</el-table-column>
				  
				  			<el-table-column label="代收方式" align="center">
				  				<template slot-scope="scope">
				  					<template v-if="scope.row.pay_type_name">
				  						<el-checkbox v-model="scope.row.pay_type_state" @change="payTypeChange(scope.row)">
				  							{{scope.row.pay_type_name}}
				  						</el-checkbox>
				  					</template>
				  				</template>
				  			</el-table-column>
				  
				  		</el-table>
				  	</div>
				  </el-form>
				  
				  <!-- 代付通道配置 -->
				  <el-form v-if="activeIndex === 'repayPower'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<div>
				  		<el-table v-loading='dwloading' height="550px" :data="newRepayWay" border size="mini" stripe  :span-method="objectSpanMethod">
				  			<el-table-column prop="name" label="通道" width="120px" align="center">
				  				<template slot-scope="scope">
				  					<el-checkbox v-model="scope.row.state" @change="payTypeChange(scope.row)">
				  						{{scope.row.name}} </el-checkbox>
				  				</template>
				  			</el-table-column>
				  
				  			<el-table-column label="代付方式" align="center">
				  				<template slot-scope="scope">
				  					<template v-if="scope.row.pay_type_name">
				  						<el-checkbox v-model="scope.row.pay_type_state" @change="payTypeChange(scope.row)">
				  							{{scope.row.pay_type_name}}
				  						</el-checkbox>
				  					</template>
				  				</template>
				  			</el-table-column>
				  
				  		</el-table>
				  	</div>
				  </el-form>
				  
				  <!-- 代收通道分流 -->
				  <el-form v-if="activeIndex === 'ratio'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<div class="cdanger textCenter mb20">代收方式下的通道的比例之和必须等于100</div>
				  	<div>
				  		<el-table v-loading='dwloading' :data="ratio" border size="mini" stripe :span-method="objectSpanMethodNewRatio">
				  			<el-table-column align="center" prop="pay_type_name" label="代收方式" width="200px">
				  				<template slot-scope="scope">
				  					{{scope.row.pay_type_name}}
				  				</template>
				  			</el-table-column>
							
				  			<el-table-column align="center" prop="payway_type_name" label="通道" width="100px">
				  				<template slot-scope="scope">
				  					{{scope.row.payway_type_name}}
				  				</template>
				  			</el-table-column>
							
				  			<el-table-column align="center" label="分流比例">
				  				<template slot-scope="scope">
				  					<el-input-number style="width:50%" controls-position="right" v-model="scope.row.ratio" placeholder=""></el-input-number>
				  				</template>
				  			</el-table-column>
				  		</el-table>
				  	</div>
				  </el-form>
				  
				  <!-- 代付通道分流 -->
				  <el-form v-if="activeIndex === 'repayRatio'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<div class="cdanger textCenter mb20">代付方式下的通道的比例之和必须等于100</div>
				  	<div>
				  		<el-table v-loading='dwloading' :data="repayRatioData" border size="mini" stripe :span-method="objectSpanMethodNewRatio">
				  			<el-table-column align="center" prop="pay_type_name" label="代付方式" width="200px">
				  				<template slot-scope="scope">
				  					{{scope.row.pay_type_name}}
				  				</template>
				  			</el-table-column>
				  			<el-table-column align="center" prop="payway_type_name" label="通道" width="100px">
				  				<template slot-scope="scope">
				  					{{scope.row.payway_type_name}}
				  				</template>
				  			</el-table-column>
				  			<el-table-column align="center" label="通道分流">
				  				<template slot-scope="scope">
				  					<el-input-number style="width:50%" controls-position="right" v-model="scope.row.ratio" placeholder=""></el-input-number>
				  				</template>
				  			</el-table-column>
				  		</el-table>
				  	</div>
				  </el-form>
				  
				  
				  <!-- 提交按钮 -->
				  <div class="textCenter">
				  	<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				  </div>
				  
			  </div>
			</el-drawer>
			
		</div>
		
		
		<!-- ------------------------------------ 抽屉  END  ----------------------------------- -->
		
		
		
		<el-dialog center class="dialog" top="100px" :title="title" :visible.sync="dialogVisible" @close="close" :close-on-click-modal="false" width="35%">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-form size="mini" :label-position="labelPosition" label-width="100px" :model="form" v-if="type === 'new'|| type === 'edt'||type === 'payPassword'||type === 'password'">
						<el-form-item :label="$t('main.accountMenu.agentName')" v-if="type === 'new'">
						<el-cascader v-if="type === 'new'" v-model="form.agent" class="mr20" :show-all-levels="false" placeholder="请选择代理" size="mini" :options="agentNameArr" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
						</el-form-item>
						
						<el-form-item :label="$t('main.accountMenu.agentName')" v-if="type === 'password' || type === 'edt' || type === 'payPassword'">
							<el-input size="mini" autocomplete="new-password" :disabled="true" v-model="form.agent" placeholder=""></el-input>
						</el-form-item>
						
						<el-form-item label="商户类型" v-if="type === 'edt' || type === 'new'">
							  <el-select size="mini" v-model="form.typeValue" placeholder="请选择">
							    <el-option
							      v-for="item in typeList"
							      :key="item.id"
							      :label="item.name"
							      :value="item.id">
							    </el-option>
							  </el-select>
						</el-form-item>
						<el-form-item label="商户名称" v-if="type === 'new' || type === 'edt' || type === 'payPassword'">
							<el-input size="mini" autocomplete="new-password" :disabled="type === 'payPassword'"
								v-model="form.business_name" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="登录账号" v-if="type === 'new' || type === 'edt' || type === 'payPassword'">
							<el-input size="mini" :disabled="type === 'payPassword'||type === 'edt'" v-model="form.username" placeholder="请输入登录账号"></el-input>
						</el-form-item>

						<el-form-item label="登录密码" v-if="type === 'new'">
							<el-input size="mini" autocomplete="new-password" type="password" v-model="form.password"
								placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码" v-if="type === 'new'">
							<el-input size="mini" autocomplete="new-password" type="password" v-model="passwordSure"
								placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="商户备注" v-if="type === 'new' || type === 'edt'">
							<el-input size="mini" v-model="form.name" placeholder="请输入备注"></el-input>
						</el-form-item>
						<el-form-item label="密钥" v-if="type === 'edt'">
							<span class="mr10">{{form.md5_key}}</span>
							<el-popconfirm
							  confirm-button-text='确定'
							  cancel-button-text='取消'
							  icon="el-icon-info"
							  icon-color="red"
							  title="确定重置商户密钥吗？"
							  @confirm="resetKey(id)"
							>
							  <el-button type="text" size="mini" slot="reference">重置</el-button>
							</el-popconfirm>
						</el-form-item>
						
						<el-form-item label="登录失败" v-if="type === 'edt'">
							<span class="mr10">{{form.fail_count}}</span>
							<el-popconfirm
							  confirm-button-text='确定'
							  cancel-button-text='取消'
							  icon="el-icon-info"
							  icon-color="red"
							  title="确定重置连续失败次数吗？"
							  @confirm="resetFailCount(id)"
							>
							  <el-button type="text" size="mini" slot="reference">重置</el-button>
							</el-popconfirm>
						</el-form-item>
						
						<el-form-item label="商户状态" v-if="type === 'edt'">
							<el-switch size="mini" v-model="status" class="mr20"></el-switch>
							<span>{{status?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
						</el-form-item>
						<el-form-item label="api支付" v-if="type === 'edt'">
							<el-switch size="mini" v-model="status_order" class="mr20"></el-switch>
							<span>{{status_order?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
						</el-form-item>
						<el-form-item label="api代付" v-if="type === 'edt'">
							<el-switch size="mini" v-model="status_pay" class="mr20"></el-switch>
							<span>{{status_pay?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
						</el-form-item>
						<el-form-item label="后台代付" v-if="type === 'edt'">
							<el-switch size="mini" v-model="is_can_trader_repay" class="mr20"></el-switch>
							<span>{{is_can_trader_repay?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
						</el-form-item>
						<el-form-item label="权重类型" v-if="type === 'edt'">
							  <el-radio v-model="weight_type" label="1">商户</el-radio>
							  <el-radio v-model="weight_type" label="2">通道</el-radio>
						</el-form-item>
						<!-- <el-form-item label="代付自动提交" v-if="type === 'edt'">
							<el-switch v-model="repay_type" class="mr20"></el-switch>
							<span>{{repay_type?'自动':'手动'}}</span> （商户代付订单是否自动提交到绑定的通道）
						</el-form-item> -->
						<!-- <el-form-item label="订单金额状态" v-if="type === 'edt'">
							<el-switch v-model="status_gold_limit" class="mr20"></el-switch>
							<span>{{status_gold_limit?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span> （启用后必须到账金额等于提交金额才能确认订单）
						</el-form-item> -->
						<!-- <el-form-item label="代收匹配代付" v-if="type === 'edt'">
							<el-switch v-model="is_collection" class="mr20"></el-switch>
							<span>{{is_collection?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span> （启用后，三方代收订单将允许匹配给代付订单）
						</el-form-item> -->
						<!-- <el-form-item label="代付匹配代收" v-if="type === 'edt'">
							<el-switch v-model="is_payment" class="mr20"></el-switch>
							<span>{{is_payment?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span> （启用后，三方代付订单将优先匹配给代收订单）
						</el-form-item> -->
						<!-- 修改密码 -->
						<el-form-item label="代付密码" v-if="type === 'payPassword'">
							<el-input type="password" v-model="form.password" placeholder="请输入代付密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" v-if="type === 'payPassword'">
							<el-input type="password" v-model="passwordSure" placeholder="请再次输入密码"></el-input>
						</el-form-item>
					</el-form>
					
					<el-form :model="amountForm" size="mini" v-if="type === 'freeze' || type ==='unFreeze'" :label-position="labelPosition" class="mb20"  label-width="50px">
						<div class="flexY flexAround mb20">
							<el-input class="mb20" placeholder="请输入内容" v-model="this.item.business_no">
								<template slot="prepend">商户ID:</template>
							</el-input>
							<el-input placeholder="请输入内容" v-model="this.item.business_name">
								<template slot="prepend">商户名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">当前余额: <span class="tabletdColor fs20">￥{{this.item.money}}</span></div>
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
						    <el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除余额，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
						    <el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
						    <el-input
						      type="textarea"
						      :rows="3"
						      placeholder="请输入备注"
						      v-model="amountForm.amountNote">
						    </el-input>
						  </el-form-item>
						
					</el-form>
					
					<el-form :model="amountForm" size="mini" v-if="type === 'yufu'" :label-position="labelPosition" class="mb20"  label-width="50px">
						<div class="flexY flexAround mb20">
							<el-input class="mb20" placeholder="请输入内容" v-model="this.item.business_no">
								<template slot="prepend">商户ID:</template>
							</el-input>
							<el-input placeholder="请输入内容" v-model="this.item.business_name">
								<template slot="prepend">商户名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">当前预付: <span class="tabletdColor fs20">￥{{this.item.money_repaid}}</span></div>
						 <el-form-item label="调预付:" prop="amount" :rules="{ required: true, message: '调预付不能为空'}">
						    <el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除预付，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
						    <el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
						    <el-input
						      type="textarea"
						      :rows="3"
						      placeholder="请输入备注"
						      v-model="amountForm.amountNote">
						    </el-input>
						  </el-form-item>
						
					</el-form>
					<el-form v-if="type === 'inOutUnMoney'" :label-position="labelPosition"
						label-width="80px" class="mb20">
						<!-- <span class="mr20">当前余额：{{money}}</span>
						<span class="mr20">当前冻结余额：{{money_uncertain}}</span> -->
						<el-form-item class="mt20" label="币种:">
							<el-radio-group v-model="freezeCurrency" style="line-height: 30px;">
								<template v-for="item of currencyFreeZeList.currencys">
									<el-radio :label="item.id" :key="item.id">{{item.name}} (余额：{{item.money}} <span class="ml20">冻结余额：{{item.money_uncertain}} </span> </span>)</el-radio>
								</template>
							</el-radio-group>
						</el-form-item>
						<el-form-item class="mt20" label="增减方式">
							<el-radio v-model="unmoneyRadio" label="IN">增加</el-radio>
							<el-radio v-model="unmoneyRadio" label="OUT">扣除</el-radio>
						</el-form-item>
						<el-form-item class="mt20" label="金额">
							<el-input v-model="unmoneyAmount" placeholder="请输入金额"></el-input>
						</el-form-item>
						
					</el-form>
					
					
					<el-form v-if="type === 'clearUnBalance'" :label-position="labelPosition"
						label-width="120px" class="mb20">
						<el-form-item class="mt20" label="币种:">
							<el-radio-group v-model="freezeCurrency" style="line-height: 30px;">
								<template v-for="item of currencyFreeZeList.currencys">
									<el-radio :label="item.id" :key="item.id">{{item.name}} (余额：{{item.money}} <span class="ml20">冻结余额：{{item.money_uncertain}} </span> </span>)</el-radio>
								</template>
							</el-radio-group>
						</el-form-item>
						<el-form-item class="mt20" label="销毁冻结余额">
							<el-input v-model="amount" placeholder="请输入销毁余额"></el-input>
						</el-form-item>
						
					</el-form>
					<el-form v-if="type === 'offlineFee'" :label-position="labelPosition" label-width="80px" class="mb20">						
						<el-table :data="offlineFeeTable" stripe size="mini" border>
							<el-table-column prop="name" label="币种名称" align="center"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-input style="width: 80px;" v-model="scope.row.fee" placeholder="请输入手续费" class="mr20"></el-input>
									<el-link type="primary" class="mr20"> 最小值:{{scope.row.agent_fee}} </el-link>
								</template>
							</el-table-column>
						</el-table>
					</el-form>
					
					<el-form v-if="type === 'ratio'" :label-position="labelPosition" label-width="80px" class="mb20">
						<div class="cdanger textCenter mb20">支付方式下的通道的比例之和必须等于100</div>
						<div>
							<el-table :data="ratio" border size="mini" stripe :span-method="objectSpanMethodNewRatio">
								<el-table-column prop="pay_type_name" label="支付方式" width="200px">
									<template slot-scope="scope">
										{{scope.row.pay_type_name}}
									</template>
								</el-table-column>
								<el-table-column prop="cur_name" label="币种" width="100px">
									<template slot-scope="scope">
										{{scope.row.cur_name}}
									</template>
								</el-table-column>
								<el-table-column label="通道" prop="payway_type_name">
								</el-table-column>
								<el-table-column label="通道比例">
									<template slot-scope="scope">
										<el-input-number style="width:100%" controls-position="right" v-model="scope.row.ratio" placeholder=""></el-input-number>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-form>
					<el-form v-if="type === 'whitelist'" :label-position="labelPosition" label-width="100px"
						class="mb20">
						<div class="cdanger textCenter">白名单多个IP请用英文状态下的","分割</div>
						<el-form-item class="mt20" label="登录白名单">
							<el-input type="textarea" v-model="fileWhiteList_login" placeholder="请输入登录白名单"></el-input>
						</el-form-item>
						<el-form-item class="mt20" label="代付白名单">
							<el-input type="textarea" v-model="fileWhiteList_repay" placeholder="请输入代付白名单"></el-input>
						</el-form-item>
					</el-form>

					<el-form v-if="type === 'traderFeeNum'" :label-position="labelPosition" label-width="80px"
						class="mb20">
						<el-form-item class="mt20" label="手续费">
							<el-input v-model="traderFeeNum.fee_num" placeholder="请输入商家单笔手续费"></el-input>
						</el-form-item>
					</el-form>
					<el-form v-if="type ==='keepGold'" :label-position="labelPosition" label-width="120px" class="mb20">
						<el-form-item class="mt20" label="余额留存上线">
							<el-input v-model="item.trader_keep_gold" placeholder="请输入余额"></el-input>
						</el-form-item>
					</el-form>
					<el-form v-if="type ==='payMatchType'" :label-position="labelPosition" label-width="120px" class="mb20">
						<el-form-item class="mt20" label="姓名匹配模式">
							<!-- <el-input v-model="item.trader_keep_gold" placeholder="请输入余额"></el-input> -->
							<el-switch v-model="status_payMatchType" class="mr20"></el-switch>
							<span>{{status_payMatchType?'开启':'关闭'}}</span>
						</el-form-item>
					</el-form>
					<el-form v-if="type ==='bindBusinessCurrency'" :label-position="labelPosition" label-width="120px" class="mb20">
						  <!-- <el-checkbox :indeterminate="checkBoxTraderCurrency.isIndeterminate" v-model="checkBoxTraderCurrency.checkAll" @change="handleCheckCurrencyAllChange">全选</el-checkbox> -->
						  <div style="margin: 15px 0;"></div>
						  <el-checkbox-group  v-model="checkBoxTraderCurrency.checkedCurs"  @change="handleCheckedCurrencyChange">
						    <el-checkbox v-for="cur in checkBoxTraderCurrency.traderCurrencys" :label="cur.id" :key="cur.id">{{cur.name}}</el-checkbox>
						  </el-checkbox-group>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 对接信息 -->
		<el-dialog
		  title="接入信息"
		  :visible.sync="gatewayDialog"
		  width="30%"
		  center>
		  <div>
			<div class="flexX flexStart flexBaseline">
				<span class="mb10 pl20 mr10">商户后台：{{gatewayInfo.traderAddr}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.traderAddr)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mb10 pl20 mr10">登录账号：{{gatewayInfo.username}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.username)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mb10 pl20 mr10">初始密码：{{gatewayInfo.pwd == ''?'******':''}}</span>
				 <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.pwd)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">商户ID：{{gatewayInfo.traderId}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.traderId)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">商户密钥：{{gatewayInfo.traderKey}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.traderKey)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">回调ip：{{gatewayInfo.callbackIp}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.callbackIp)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">注意：为了账户安全，请先登录页面绑定谷歌验证码，登录成功后修改账号密码</span>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">支付下单：{{gatewayInfo.payAddr}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.payAddr)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">支付查询：{{gatewayInfo.payQuery}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.payQuery)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">代付下单：{{gatewayInfo.repayAddr}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.repayAddr)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">代付查询：{{gatewayInfo.repayQuery}} </span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.repayQuery)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">余额查询：{{gatewayInfo.queryQuota}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.queryQuota)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">支付对接文档：{{gatewayInfo.payDoc}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.payDoc)"></i>
			</div>
			<div class="flexX flexStart flexBaseline">
				<span class="mr10 mb10 pl20">代付对接文档：{{gatewayInfo.repayDoc}}</span>
				<i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(gatewayInfo.repayDoc)"></i>
			</div>
			<div>
				<span class="mr10 mb10 pl20">支付测试编码：111（请联系客服开通测试通道，拉起成功后，可自助回调。仅供测试！！！）</span>
			</div>
			  
		  </div>
		  <el-link :underline="false" @click="handleCopyALL()"><i style="color: #0039ff;" class="el-icon-document-copy mt10 pl20 mb10">全部复制</i></el-link>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="gatewayDialog = false">确 定</el-button>
		  </span>
		</el-dialog>
		
		<!-- 日志 -->
		<el-dialog class="dialog" top="100px" center title="日志" :visible.sync="logdialog" @close="logclose"
			:close-on-click-modal="false" width="70%">
			<el-tabs type="border-card" @tab-click="getlogData" v-model="logName">
				<el-tab-pane label="增加|扣除" name='recharge' :disabled="logLoding">
					<el-table size="mini" :data="rechargeLog" border="" stripe max-height="500px">
						<el-table-column prop="name" label="操作类型" align="center">
							<template slot-scope='scope'>
								<div :class="{csuccess:scope.row.name=='增加'||scope.row.name=='充值冻结余额',cdanger:scope.row.name!='扣除' && scope.row.name!='充值冻结余额'}">{{scope.row.name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="币种" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.curType}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="交易人" align="center"></el-table-column>
						<el-table-column prop="time" label="交易时间" align="center"></el-table-column>

					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="reLoghandleSizeChange"
							@current-change="reLoghandleCurrentChange" :current-page="reLogpage"
							:page-sizes="reLogpageSizesArr" :page-size="reLogpageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="reLogtotal"></el-pagination>
					</div>
				</el-tab-pane>

				<el-tab-pane label="手续费" name='fee' :disabled="logLoding">
					<el-table size="mini" :data="feeLog" border="" stripe max-height="500px">
						<!-- <el-table-column prop="card" label="卡号" width="145px" align="center"></el-table-column> -->
						<el-table-column label="支付类型" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.pay_type_name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="费率" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.fee}}</div>
							</template>
						</el-table-column>
						<el-table-column label="旧费率" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_fee}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作者" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="feeLoghandleSizeChange"
							@current-change="feeLoghandleCurrentChange" :current-page="feeLogpage"
							:page-sizes="feeLogpageSizesArr" :page-size="feeLogpageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="feeLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>

		</el-dialog>
		
		<!-- 所有额度变动日志 -->
		<el-dialog class="dialog" top="20px" center title="总体日志" :visible.sync="allLogdialog" @close="allLogclose" :close-on-click-modal="false" width="80%">
			<el-tabs type="border-card" @tab-click="allGetlogData" v-model="logName">
				<el-tab-pane label="余额变动" name='recharge' :disabled="logLoding">
					<div>
						<!-- <el-cascader v-model="carder_id" class="mr20 mb20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang" ></el-cascader> -->
					<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="log_business_no" clearable class="mr20" style="width: 200px"></el-input>
					
					<el-select v-model="log_type" placeholder="请选择类型" size="mini" class="mr20" clearable>
						<el-option key="IN" label="增加" value="IN"></el-option>
						<el-option key="OUT" label="减少" value="OUT"></el-option>
					</el-select>
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="logListgetData">搜索</el-button>
						
					</div>
						 
					<el-table size="mini" :data="allRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="商户ID" align="center">
							<template slot-scope='scope'>
								<div>
									{{scope.row.business_no}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="商户" align="center">
							<template slot-scope='scope'> 
								{{scope.row.business_name}}
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" align="center">
							<template slot-scope='scope'>
								<div v-if="scope.row.name=='增加'" class="csuccess">{{scope.row.money}}</div>
								<div v-else-if="scope.row.name=='扣除'" class="cdanger">{{scope.row.money}}</div>
								<div v-else class="cwarning">{{scope.row.money}}</div>
								
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip prop="note" label="备注" align="center"></el-table-column>
						<el-table-column prop="auditor" label="操作人" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="allReLogpage" :page-sizes="allReLogpageSizesArr" :page-size="allReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="allReLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="预付明细" name='yufu' :disabled="logLoding">
					<div>
						<!-- <el-cascader v-model="carder_id" class="mr20 mb20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang" ></el-cascader> -->
					<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="yufulog_business_no" clearable class="mr20" style="width: 200px"></el-input>
					
					<el-select v-model="log_type" placeholder="请选择类型" size="mini" class="mr20" clearable>
						<el-option key="IN" label="增加" value="IN"></el-option>
						<el-option key="OUT" label="减少" value="OUT"></el-option>
					</el-select>
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="yufulogListgetData">搜索</el-button>
						
					</div>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="yufuallReLogpage" :page-sizes="yufuallReLogpageSizesArr" :page-size="yufuallReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="yufuallReLogtotal"></el-pagination>
					</div>
							 
						<el-table size="mini" :data="yufuallRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
							<el-table-column label="商户ID" align="center">
								<template slot-scope='scope'>
									<div>
										{{scope.row.business_no}}
									</div>
								</template>
							</el-table-column>
							<el-table-column label="商户" align="center">
								<template slot-scope='scope'> 
									{{scope.row.business_name}}
								</template>
							</el-table-column>
							<el-table-column label="操作前金额" align="center">
								<template slot-scope='scope'>
									<div>{{scope.row.old_money | tofixed}}</div>
								</template>
							</el-table-column>
							<el-table-column label="操作金额" align="center">
								<template slot-scope='scope'>
									<div v-if="scope.row.name=='增加'" class="csuccess">{{scope.row.money}}</div>
									<div v-else-if="scope.row.name=='扣除'" class="cdanger">{{scope.row.money}}</div>
									<div v-else class="cwarning">{{scope.row.money}}</div>
									
								</template>
							</el-table-column>
							<el-table-column label="操作后金额" align="center">
								<template slot-scope='scope'>
									<div>{{scope.row.new_money | tofixed}}</div>
								</template>
							</el-table-column>
							<el-table-column show-overflow-tooltip prop="note" label="备注" align="center"></el-table-column>
							<el-table-column prop="auditor" label="操作人" align="center"></el-table-column>
							<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
						
						</el-table>
				</el-tab-pane>
				
					<el-tab-pane label="预付报表" name='prepay' :disabled="logLoding">
						<div>
							<!-- <el-cascader v-model="carder_id" class="mr20 mb20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang" ></el-cascader> -->
						<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="prepaylog_business_no" clearable class="mr20" style="width: 200px"></el-input>
						
							<el-button size="mini" plain class="mr20" @click="changeDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
							    <el-date-picker
								size="mini"
								class="mr10"
							      v-model="prepaydate"
							      type="date"
							      placeholder="选择日期">
							    </el-date-picker>
							<el-button size="mini" plain class="mr20" @click="changeDate(1)" :disabled="cardListdisLoading">后一天</el-button>
							<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="prepaylogListgetData">搜索</el-button>
							
						</div>
						 
					<el-table size="mini" :data="prepayallRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="商户ID" align="">
							<template slot-scope='scope'>
								<div>
									{{scope.row.business_no}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="商户" align="">
							<template slot-scope='scope'> 
								{{scope.row.business_name}}
							</template>
						</el-table-column>
						<el-table-column label="昨日" align="">
						    <template slot-scope='scope'>
						        <div>剩余预付：{{ scope.row.yesterdayRepaid | tofixed }}</div>
						        <div>剩余余额：{{ scope.row.yesterdayQuota | tofixed }}</div>
						        <div :style="{ color: scope.row.yesterdayNetRepaid > 0 ? 'green' : (scope.row.yesterdayNetRepaid < 0 ? 'red' : '') }">
						            净预付：{{ scope.row.yesterdayNetRepaid | tofixed }}
						        </div>
						    </template>
						</el-table-column>

						<el-table-column label="当日总预付" align="">
							<template slot-scope='scope'>
								<div>{{scope.row.repaidMoney | tofixed}}</div>
								
							</template>
						</el-table-column>
						<el-table-column label="当日结算" align="">
							<template slot-scope='scope'>
								<div>{{scope.row.alreadySettleMoney | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="当日" align="">
							<template slot-scope='scope'>
								<div>剩余预付：{{scope.row.syRepaid | tofixed}}</div>
								<div>剩余余额：{{scope.row.syQuota | tofixed}}</div>
								<div :style="{ color: scope.row.yesterdayNetRepaid > 0 ? 'green' : (scope.row.yesterdayNetRepaid < 0 ? 'red' : '') }">
									净预付：{{scope.row.NetRepaid | tofixed}}
								</div>
							</template>
						</el-table-column>
					
					</el-table>
						<div class="flexX  mt20">
							<el-pagination :hide-on-single-page="true" @size-change="prepayallReLoghandleSizeChange" @current-change="prepayallReLoghandleCurrentChange"
							 :current-page="prepayallReLogpage" :page-sizes="prepayallReLogpageSizesArr" :page-size="prepayallReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
							 :total="prepayallReLogtotal"></el-pagination>
						</div>
					</el-tab-pane>
			</el-tabs>
			
		</el-dialog>
		
		<!-- 结算 -->
		<el-dialog title="结算" width="75%" center :visible.sync="jiesuanDialog"  @close="jiesuanClose" :close-on-click-modal="false" >
			<div class="flexX flexBetween mb10" style="border-bottom: 1px solid black;">
				<span>商户id:{{this.jsItem.business_no}}</span>
				<span>商户名称:{{this.jsItem.business_name}}</span>
				<span>
					状态:
					<el-tag size="mini" v-if="this.jsData.state == 1" type="success">已结算</el-tag>
					<el-tag size="mini" v-if="this.jsData.state == 0" type="danger">未结算</el-tag>
					</span>
				<span>日期:{{this.jsData.add_date}}</span>
			</div>
			<div class="flexX flexBetween mb10" style="width: 100%; border-bottom: 1px solid black;">
				<div style="border-right: 1px solid black;width: 25%;">
					<el-tag size="mini" type="success">总入账：{{this.jsData.totalTraderInMoney}}</el-tag>
					<el-table :data="this.jsData.orderInData" border size="mini">
					  <el-table-column property="pay_type" label="支付方式"></el-table-column>
					  <el-table-column property="success_money" label="成功金额"></el-table-column>
					  <el-table-column property="trader_gold" label="入账金额"></el-table-column>
					</el-table>
				</div>
				<div style="border-right: 1px solid black;width: 25%;">
					<el-tag size="mini" type="success">总出账：{{this.jsData.totalTraderOutMoney}}</el-tag>
					<el-table :data="this.jsData.orderOutData" border size="mini">
					  <el-table-column property="pay_type" label="代付方式"></el-table-column>
					  <el-table-column property="success_money" label="成功金额"></el-table-column>
					  <el-table-column property="trader_gold" label="出账金额"></el-table-column>
					</el-table>
				</div>
				<div style="border-right: 1px solid black; width: 25%;">
					<el-tag size="mini" type="success">总调额：{{this.jsData.totalTraderChangeMoney}}</el-tag>
					<el-table :data="this.jsData.moneyChangeData" border size="mini">
					  <el-table-column property="money" label="金额">
						  <template slot-scope='scope'>
						  	<div>
								<span class="csuccess" v-if="scope.row.change_type == 'IN'">+{{scope.row.money}}</span>
								<span class="cdanger" v-if="scope.row.change_type == 'OUT'">-{{scope.row.money}}</span>
						  	</div>
						  </template>
					  </el-table-column>
					  <el-table-column property="notes" label="备注" show-overflow-tooltip></el-table-column>
					</el-table>
				</div>
				<div style="width: 25%;">
					<el-tag size="mini" type="success">预付：{{this.jsData.totalTraderRepaidMoney}}</el-tag>
					<el-table :data="this.jsData.moneyRepaidChangeData" border size="mini">
					  <el-table-column property="money" label="金额">
						  <template slot-scope='scope'>
						  	<div>
								<span class="csuccess" v-if="scope.row.change_type == 'IN'">+{{scope.row.money}}</span>
								<span class="cdanger" v-if="scope.row.change_type == 'OUT'">-{{scope.row.money}}</span>
						  	</div>
						  </template>
					  </el-table-column>
					  <el-table-column property="notes" label="备注" show-overflow-tooltip></el-table-column>
					</el-table>
				</div>
				
			</div>
			<div>
				<div class="mb10">应结算:<span ><el-tag size="medium" type="danger">{{this.jsData.shouldSettleMoney}}</el-tag></span></div>
				<div class="mb10">已结算:<span ><el-tag size="mini" type="success">{{this.jsData.alreadySettleMoney}}</el-tag></span></div>
				<div>未结算:<span ><el-tag size="mini" type="info">{{this.jsData.notSettleMoney}}</el-tag></span></div>			
				<div>(商户入账:{{this.jsData.totalTraderInMoney}} - 商户出账:{{this.jsData.totalTraderOutMoney}} + 商户调额:{{this.jsData.totalTraderChangeMoney}} - 已结算:{{this.jsData.alreadySettleMoney}} = 应结算:{{this.jsData.shouldSettleMoney}})</div>
				
			</div>
			<div class="flexX flexEnd" >
				<el-button  v-loading="dialogloading" size="mini" v-if="this.jsData.state == 0" @click="jst()" type="success">结算</el-button>
			</div>
		  
		</el-dialog>
		<el-dialog title="结算明细" width="70%" center :visible.sync="jsDialog"  @close="jsClose" :close-on-click-modal="false" >
			<div class="flexX flexBetween mb10" >
				<div  class="dataTable">
					<div class="flexY mb10">
						<div>
							商户号：<span>{{this.jsmxItem.business_no}}</span>
						</div>
						<div>
							商户名称：<span>{{this.jsmxItem.business_name}}</span>
						</div>
						
					</div>
					<el-table :data="this.jsmxData" border size="mini" class="dataTable">
					  <el-table-column property="add_date" label="日期"></el-table-column>
					  <el-table-column label="状态">
						  <template slot-scope='scope'>
						  		<span class="csuccess" v-if="scope.row.state == 1">已结算</span>
						  		<span class="cdanger" v-else>未结算</span>
						  </template>
					  </el-table-column>
					  <el-table-column property="shouldSettleMoney" label="应结算"></el-table-column>
					  <el-table-column property="alreadySettleMoney" label="已结算"></el-table-column>
					  <el-table-column property="notSettleMoney" label="未结算"></el-table-column>
					  <el-table-column property="totalTraderInMoney" label="入账金额"></el-table-column>
					  <el-table-column property="totalTraderOutMoney" label="出账金额"></el-table-column>
					  <el-table-column property="totalTraderChangeMoney" label="调额"></el-table-column>
					  <el-table-column property="totalTraderRepaidMoney" label="预付"></el-table-column>
					  <el-table-column  label="操作">
						  <template slot-scope='scope'>
						  		<el-link class="mr10" type="primary" @click="jiesuan(scope.row, 'mx')">结算详情</el-link>
						  </template>
						  
					  </el-table-column>
					</el-table>
				</div>
				
			</div>
			<div class="flexX  mt20">
				<el-pagination :hide-on-single-page="true" @size-change="jsSizeChange" @current-change="jsCurrentChange"
				 :current-page="jspage" :page-sizes="jspageSizesArr" :page-size="jspageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="jstotal"></el-pagination>
			</div>
		  
		</el-dialog>
		<template>
			<!-- 一键配置商户费率 -->
			<el-drawer
				size="60%"
				title="一键配置费率"
				:visible.sync="feeDrawer"
				direction="rtl"
				:before-close="feeHandleClose"
				with-header
			>
				<el-form
					:label-position="labelPosition"
					label-width="80px"
					class="formBox drawer-content"
					style="padding-bottom: 80px" 
				>
					<el-row :gutter="10">
						<el-col :span="12">
							<el-form-item class="mt10" label="产品类型">
								<el-select size="mini" @change="cpChange" v-model="cpType" placeholder="请选择产品类型">
									<el-option
										v-for="item in cpTypes"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item class="mt10" label="产品">
								<el-select size="mini" @change="cpDataChange" v-model="cpData" placeholder="请选择产品">
									<el-option
										v-for="item in cpDatas"
										:key="item.id"
										:label="'【' + item.id + '】' + item.name"
										:value="item.id"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
		
					<!-- 一键填入功能 -->
					<el-form-item label="一键填入">
						<template>
							<el-row :gutter="10" class="mb10">
								<el-col :span="4">
									<el-input size="mini" v-model="bulkFee" placeholder="统一费率" />
								</el-col>
								<el-col v-if="cpType == 2" :span="4">
									<el-input size="mini" v-model="bulkFeeNum" placeholder="单笔费率" />
								</el-col>
								<el-col :span="4">
									<el-input size="mini" v-model="bulkMinGold" placeholder="最低限额" />
								</el-col>
								<el-col :span="4">
									<el-input size="mini" v-model="bulkMaxGold" placeholder="最高限额" />
								</el-col>
								<el-col :span="4">
									<el-button size="mini" type="primary" @click="applyBulkValues">一键填入</el-button>
								</el-col>
							</el-row>
						</template>
					</el-form-item>
		
					<el-form-item label="商户">
						<template>
							<el-table :data="feeTraderList" height="450" border size="mini" style="width: 100%">
								<el-table-column prop="business_no" label="商户" width="180">
									<template slot-scope="scope" class="">
										<div>{{ scope.row.business_no }}</div>
										<div>{{ scope.row.business_name }}</div>
									</template>
								</el-table-column>
								<el-table-column prop="business_no" label="费率" width="120">
									<template slot-scope="scope" class="flexY">
										<el-input size="mini" v-model="scope.row.fee" />
									</template>
								</el-table-column>
								<el-table-column v-if="cpType == 2" prop="business_no" label="单笔费率" width="120">
									<template slot-scope="scope" class="flexY">
										<el-input size="mini" v-model="scope.row.fee_num" />
									</template>
								</el-table-column>
								<el-table-column prop="business_no" label="单笔限额" width="160">
									<template slot-scope="scope" class="flexY">
										<el-input class="mb10" size="mini" v-model="scope.row.min_gold" placeholder="最小限额" />
										<el-input size="mini" v-model="scope.row.max_gold" placeholder="最大限额" />
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-form-item>
				</el-form>
		
				<div class="drawer-footer">
					<el-button size="mini" type="primary" @click="updateTradersFee">确认修改</el-button>
				</div>
			</el-drawer>
		</template>


		
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMoney3,
		isMoney4,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '@/util/local'
	export default {
		data() {
			return {
				dialogVisibleFee:false,
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				feepageSizesArr: [20, 50, 100, 200],
				feepageSize: 20,
				feetotal: 0,
				feepage: 1,
				
				afeepageSizesArr: [20, 50, 100, 200],
				afeepageSize: 20,
				afeetotal: 0,
				afeepage: 1,
				
				limitpage: 1,
				limitpageSizesArr: [20, 50, 100, 200],
				limitpageSize: 20,
				limittotal: 0,
				singleLimitData: [],

				//新增
				labelPosition: 'right',
				form: {
					agent: '',
					username: '',
					// business_no:'',
					business_name: '',
					password: '',
					note: '',
					name: '',
					typeValue: '',
				},
				status: true,
				status_order: true,
				is_can_trader_repay: false,
				weight_type: "2",
				repay_type: true,
				status_pay: true,
				status_payMatchType: false,
				// status_pay_api: true,
				status_gold_limit: true,
				// password: '',
				passwordSure: '', //确认密码
				type: '', //new  新增   edt修改
				id: '',
				title: '新增代理',
				titleName: '',
				submitText: this.$t('main.accountMenu.add'),
				fee: '',
				agentNameArr: [{
					id: '',
					name: '请选择代理'
				}],
				//手续费
				feeData: [],
				business_no: '',
				amount: '',
				freezeCurrency: 2,
				currencyFreeZeList:{},
				//当前余额
				money: '',
				//当前冻结余额
				money_uncertain: '',
				payWay: [], //支付权限配置
				ratio: [], //支付通道百分比
				spanArr: [],
				spanArr2:[],
				pos2:'',
				spanArrNew:[],
				pos: '',
				posNew:'',
				//Log 日志
				item: {},
				logName: 'recharge',
				logdialog: false,
				logLoding: false,
				//冻结|解冻  日志
				rechargeLog: [],
				reLogpageSizesArr: [20, 50, 100, 200],
				reLogpageSize: 20,
				reLogtotal: 0,
				reLogpage: 1,
				//限额	日志		
				deductionLog: [],
				dedLogpageSizesArr: [20, 50, 100, 200],
				dedLogpageSize: 20,
				dedLogtotal: 0,
				dedLogpage: 1,
				//费率日志
				feeLog: [],
				feeLogpageSizesArr: [20, 50, 100, 200],
				feeLogpageSize: 20,
				feeLogtotal: 0,
				feeLogpage: 1,
				traderFeeNum: {}, //商家单笔手续费
				height: 0,
				traderFee:'',//商户费率				
				afeeData: [],
				checkBoxTraderCurrency:{
					traderCurrencys: [],
					checkedCurs:[],
					isIndeterminate: true,
					checkAll: false,
				},
				newPayWay:[],
				newAgentFeeData:[],
				traderFeeDataArr:[],
				traderRepayFeeData:[],//代付手续费
				traderFeeLoading:false,
				traderRepayFeeLoading:false,
				traderFeeData:[],
				btnType:'',
				offlineFeeTable:[],
				dwloading:false,
				dwdialog:false,
				activeIndex:'fee',
				dwitem:{},
				newRepayWay:[],
				repayRatioData: [], // 代付通道分流百分比 
				// 增减冻结余额.
				unmoneyRadio: 'IN',
				unmoneyAmount: '',
				// 白名单.
				fileWhiteList_login: '',
				fileWhiteList_repay: '',
				is_show_whiteList: 2,
				// 代理&商户分类
				typeList:{},
				TypeCheckList:[],
				// 商户所有余额操作日志.
				allLogdialog: false,
				allRechargeLog: [],
				log_business_no: '',
				log_type:'',
				allReLogpageSizesArr:[50, 100, 200],
				allReLogpageSize:50,
				allReLogtotal:0,
				allReLogpage:1,
				
				// 商户预付操作日志.
				yufuallLogdialog: false,
				yufuallRechargeLog: [],
				yufulog_business_no: '',
				yufulog_type:'',
				yufuallReLogpageSizesArr:[50, 100, 200],
				yufuallReLogpageSize:50,
				yufuallReLogtotal:0,
				yufuallReLogpage:1,
				
				
				
				cardListdate:null,
				cardListdisLoading:false,
				// 余额操作备注.
				amountNote: '',
				// 代收匹配代付，开关
				is_collection: false,
				is_payment: false,
				// 查询按钮
				check_business_no: '',
				// 调额
				amountForm: {
					amount:'',
					amountNote: '',
					googleCode:'',
				},
				disLoading: false,
				// 结算.
				jiesuanDialog: false,
				jsItem: [],
				jsData: [],
				// 对接信息
				gatewayInfo: [],
				gatewayDialog: false,
				gateItem: [],
				// 结算明细
				jsmxData: [],
				jsmxItem: [],
				jsDialog: false,
				jspageSizesArr:[50, 100, 200],
				jspageSize:50,
				jstotal:0,
				jspage:1,
				
				// 商户预付报表.
				prepayallLogdialog: false,
				prepayallRechargeLog: [],
				prepaylog_business_no: '',
				prepaylog_type:'',
				prepayallReLogpageSizesArr:[50, 100, 200],
				prepayallReLogpageSize:50,
				prepayallReLogtotal:0,
				prepayallReLogpage:1,
				prepaydate: null,
				// 一键配置费率
				bulkFee: '', // 一键填入的费率
				bulkFeeNum: '', // 一键填入的单笔费率
				bulkMinGold: '', // 一键填入的最低限额
				bulkMaxGold: '', // 一键填入的最高限额
				feeDrawer: false,
				cpType: '',
				cpTypes:[
					{
						value: 1,
						label: '代收'
					},
					{
						value: 2,
						label: '代付'
					},
				],
				cpData:'',
				cpDatas:[],
				feeTraderList: [],
			}
		},
		created() {
			this.traderListtList()
			this.agentList()
			this.getTypeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
			})
			
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
			this.prepaydate = new Date();
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					// return ((Math.floor((value * 1)*100)/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val = Math.floor(((value * 1) * 10000).toPrecision(12))
					return ((val / 10000).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			},
			tofixed_two: function(value) {
				if (value || value == 0) {
					let val =Math.floor(((value * 1)*10000).toPrecision(12))
					return ((val/10000).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			applyBulkValues() {
			  // 一键填入功能，遍历费率列表，应用一键填入的值
			  this.feeTraderList.forEach(item => {
				if (this.bulkFee) item.fee = this.bulkFee;
				if (this.bulkFeeNum && this.cpType == 2) item.fee_num = this.bulkFeeNum;
				if (this.bulkMinGold) item.min_gold = this.bulkMinGold;
				if (this.bulkMaxGold) item.max_gold = this.bulkMaxGold;
			  });
			},
			updateTradersFee(){
				let data = this.feeTraderList
				data = JSON.stringify(data);
				let obj = {
					type: this.cpType,
					pay_type: this.cpData,
					data: data,
				}
				
				this.dialogloading = true
				this.$api.setMemberFee(obj).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.feeTraderList = []
						this.getFeeTraderList(this.cpData)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					this.dialogloading = false
				}).catch(error => {
					this.dialogloading = false
				})
			},
			// 查询代收方式
			payTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.payTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.cpDatas = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			// 代付方式列表
			repayTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.repayTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.cpDatas = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			getFeeTraderList(pay_type){
				let data = {
					'type': this.cpType,
					'pay_type': pay_type
				}
				this.logLoding = true;
				this.$api.getMemberFee(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.feeTraderList = res.data
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			cpDataChange(val){
				this.feeTraderList = []
				this.feeTraderChecked = []
				this.getFeeTraderList(val)
			},
			cpChange(val){
				this.cpData = '';
				this.cpDatas = [];
				this.feeTraderList = []
				this.feeTraderChecked = []
				if(val == 1){
					this.payTypeAll()
				}
				if(val == 2){
					this.repayTypeAll()
				}
			},
			openFeeDrawer(){
				this.feeDrawer = true
				
			},
			feeHandleClose(){
				this.feeDrawer = false
				this.cpType = ''
				this.cpData = '';
				this.cpDatas = [];
				this.feeTraderList = []
				this.feeTraderChecked = []
			},
			jsSizeChange(val){
				this.jspage = 1
				this.jspageSize = val
				this.getJsDataList();
			},
			jsCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.jspage = val
				this.getJsDataList();
			},
			jsmx(item){
				this.jsmxItem = item;
				this.getJsDataList();
				this.jsDialog = true;
			},
			jsClose(){
				this.jsmxItem = [];
				this.jsmxData = []
				this.jsDialog = false;
			},
			getJsDataList(){
				let data = {
					// start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					// end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'id': this.jsmxItem.id,
					'page': this.jspage,
					'size': this.jspageSize
				}
				this.logLoding = true;
				this.$api.traderSettleList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.jsmxData = res.data.data;
						this.jstotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			delTrader(id){
				let data = {
					id: id,
				}
				this.dialogloading = true
				this.$api.deleteTrader(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.traderListtList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			resetKey(id){
				let data = {
					id: id,
				}
				this.dialogloading = true
				this.$api.setTraderKey(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.form.md5_key = res.data
						this.traderListtList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			resetFailCount(id){
				let data = {
					id: id,
				}
				this.dialogloading = true
				this.$api.setTraderFailCount(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.form.fail_count = res.data
						this.traderListtList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			handleCopyALL(){
				  // 拼接内容
const content = `
商户后台：${this.gatewayInfo.traderAddr} 
登录账号：${this.gatewayInfo.username}
初始密码：${this.gatewayInfo.pwd === '' ? '******' : this.gatewayInfo.pwd}
商户ID：${this.gatewayInfo.traderId} 
商户密钥：${this.gatewayInfo.traderKey} 
回调ip：${this.gatewayInfo.callbackIp} 
注意：为了账户安全，请先登录页面绑定谷歌验证码，登录成功后修改账号密码

支付下单：${this.gatewayInfo.payAddr}
支付查询：${this.gatewayInfo.payQuery}
代付下单：${this.gatewayInfo.repayAddr}
代付查询：${this.gatewayInfo.repayQuery} 
余额查询：${this.gatewayInfo.queryQuota}
支付对接文档：${this.gatewayInfo.payDoc} 
代付对接文档：${this.gatewayInfo.repayDoc}
支付测试编码：111（请联系客服开通测试通道，拉起成功后，可自助回调。仅供测试！！！）
`;
				this.handleCopy(content.trim())
			},
			jst(){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.jsData.id,
					}
					this.dialogloading = true
					this.$api.traderSettle(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getJsData()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消结算`
					});
				});
			},
			jiesuan(item, type){
				this.jsItem = item;
				if(type == 'mx'){
					this.cardListdate = [item.add_date+' 00:00:00', item.add_date+' 23:59:59']
					this.jsItem = this.jsmxItem
				}
				this.getJsData();
				this.jiesuanDialog = true;
			},
			jiesuanClose(){
				this.jsItem = [];
				this.jsData = []
				this.jiesuanDialog = false;
			},
			getJsData(){
				let data = {
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'business_no': this.jsItem.business_no,
					'id': this.jsItem.id,
				}
				this.logLoding = true;
				this.$api.settleDetail(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.jsData = res.data;
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			// 复制
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			getData(){
				// this.diapage = 1;
				this.traderListtList()
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.traderListtList()
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				
				let startdate = this.date ? this.date[0] : ''
				let enddate = this.date ? this.date[1] : ''
				
				let username = local.get('fourusername');
				let tid = local.get('fourtid')
				let href =
					`${this.$baseURL}/manager/member/exportTrader?username=${username}&tid=${tid}&page=1&size=500&type=${this.TypeCheckList.join(',')}&start_time=${startdate}&end_time=${enddate}`
					
				// window.location.href = href
				window.open(href)
			},
			logListsetDate(day) {
				let data = this.cardListdate?this.cardListdate[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				
				if(this.logChangeType == 'recharge'){
					this.logListgetData();
				}
				if(this.logChangeType == 'yufu'){
					this.yufulogListgetData();
				}
				// this.logListgetData()
				// console.log(this.date)
			},
			changeDate(dayOffset) {
			  // 创建一个新日期对象，避免直接修改 `prepaydate`
			  const currentDate = new Date(this.prepaydate);
			  currentDate.setDate(currentDate.getDate() + dayOffset);
			  this.prepaydate = currentDate;
			  
			  if(this.logChangeType == 'prepay'){
			  	this.prepaylogListgetData();
			  }
			},
			allReLoghandleSizeChange(val){
				this.allReLogpage = 1
				this.allReLogpageSize = val
				this.allLogList();
			},
			allReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.allReLogpage = val
				this.allLogList();
			},
			yufuallReLoghandleSizeChange(val){
				this.yufuallReLogpage = 1
				this.yufuallReLogpageSize = val
				this.yufuallLogList();
			},
			yufuallReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.yufuallReLogpage = val
				this.yufuallLogList();
			},
			prepayallReLoghandleSizeChange(val){
				this.prepayallReLogpage = 1
				this.prepayallReLogpageSize = val
				this.prepayallLogList();
			},
			prepayallReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.prepayallReLogpage = val
				this.prepayallLogList();
			},
			tableLogRowClassName({row, rowIndex}) {
				if (row.change_type === 'IN') {
					return 'Log-in-row';
				} else if (row.change_type === 'OUT') {
					return 'Log-out-row';
				}
				return '';
			},
			logListgetData(){
				this.diapage = 1;
				
				this.allLogList();
			},
			yufulogListgetData(){
				this.diapage = 1;
				
				this.yufuallLogList();
			},
			prepaylogListgetData(){
				this.diapage = 1;
				
				this.prepayallLogList();
			},
			allGetlogData(val){
				this.logChangeType = val.name
				this.allRechargeLog = []
				this.yufuallRechargeLog = []
				this.prepayallRechargeLog = []
				if(this.logChangeType == 'recharge'){
					this.allLogList();
				}
				if(this.logChangeType == 'yufu'){
					this.yufuallLogList();
				}
				if(this.logChangeType == 'prepay'){
					this.prepayallLogList();
				}
				
			},
			// 总体日志
			showAllLog(){
				this.allLogdialog = true
				this.allLogList();
			},
			allLogclose(){
				this.allLogdialog = false
				this.logName = 'recharge'
				this.allRechargeLog = []
				this.yufuallRechargeLog = []
				this.prepayallRechargeLog = []
				this.log_business_no = ''
				this.yufulog_business_no = ''
				this.prepaylog_business_no = ''
				this.log_type = ''
			},
			//余额 充值|扣款
			allLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'business_no': this.log_business_no,
					'type': this.log_type,
				}
				this.logLoding = true;
				this.$api.allLogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.allRechargeLog = res.data.data;
						this.allReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			//预付 充值|扣款
			yufuallLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'business_no': this.yufulog_business_no,
					'type': this.log_type,
				}
				this.logLoding = true;
				this.$api.moneyRepaidChangeList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.yufuallRechargeLog = res.data.data;
						this.yufuallReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			//预付报表
			prepayallLogList(){
				let data = {
					page:this.prepayallReLogpage,
					size:this.prepayallReLogpageSize,
					date: this.$util.timestampToTime(Date.parse(new Date(this.prepaydate)), true),
					'business_no': this.prepaylog_business_no,
				}
				this.logLoding = true;
				this.$api.memberRepaidReport(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.prepayallRechargeLog = res.data;
						this.prepayallReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			handleCheckedTypeChange(value){
				this.traderListtList()
			},
			getTypeList(item) {
				let data = {
					trader_id: this.id,
					business_no: this.business_no,
				}
				this.$api.typeList(data).then(res => {
					if (res.status == 1) {
						this.typeList = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			inOutUnMoneyFun(googleKey){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					if (!isMoney(this.unmoneyAmount, '余额只能是数字，最多输入两位小数')) {
						return
					}
					if(this.freezeCurrency === ''){
						this.$message({
						type: 'warning',
						message: `请选择币种`
					});
						return
					}
					let data = {
						id: this.id,
						business_no: this.business_no,
						amount: this.unmoneyAmount,
						type: this.unmoneyRadio,
						cur_id:this.freezeCurrency,
						googleCode: googleKey,
					}
					this.dialogloading = true
					this.$api.setUncertainAmount(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.traderListtList()
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${this.type === 'freeze'?'扣除金额':'增加金额'}`
					});
				});
			},
			getTraderWhiteList(){
			
				let data = {
					id: this.item.id,
					// googleCode: google_key
				}
				this.dialogloading = true
				this.$api.getTraderWhite(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.dialogVisible = true
						this.fileWhiteList_login = res.data.login_white
						this.fileWhiteList_repay = res.data.repay_white
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			openGateway(type, item){
				this.gatewayDialog = true
				this.gateItem = item
				this.getTraderGatewayInfo()
			},
			getTraderGatewayInfo(){
			
				let data = {
					id: this.gateItem.id,
				}
				this.dialogloading = true
				this.$api.traderGatewayInfo(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.gatewayInfo = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			setTraderWhiteList(google_key){
			
				let data = {
					id: this.item.id,
					login_white: this.fileWhiteList_login,
					repay_white:this.fileWhiteList_repay,
					googleCode: google_key
				}
				this.dialogloading = true
				this.$api.setTraderWhite(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.close();
						this.traderListtList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			agentList() {
				this.$api.agentList({
					// page: this.page,
					// size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						this.agentNameArr = res.data
						this.agentNameArr = res.data.filter(item => {
							if (item.name !='总计'){
								return item
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: '代理列表查询失败'
						})
					}
				}).catch(error => {})
			},
			traderListtList() {
				// this.loading = true
				this.$api.traderList({
					business_no: this.check_business_no,
					page: this.page,
					size: this.pageSize,
					type: this.TypeCheckList.join(',')
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						
						res.data.data.forEach(item=>{
							if(item.status == 0){
								item.stateA = true;
							}else{
								item.stateA = false;
							}
						})
						
						this.tableData = res.data.data
						this.total = res.data.total
						this.dataTotal = {
							all_total_money: res.data.all_total_money,
							all_total_money_uncertain: res.data.all_total_money_uncertain,
							current_total_money: res.data.current_total_money,
							current_total_money_uncertain: res.data.current_total_money_uncertain,
							all_every_cur_money: res.data.all_every_cur_money,
							all_every_cur_money_uncertain: res.data.all_every_cur_money_uncertain,
							current_every_cur_money: res.data.current_every_cur_money,
							current_every_cur_money_uncertain: res.data.current_every_cur_money_uncertain,
							all_total_money_cur: res.data.all_total_money_cur,
							all_total_money_uncertain_cur: res.data.all_total_money_uncertain_cur,
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.loading = false
					// this.$message({
					// 	type: 'error',
					// 	message: error
					// })
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.traderListtList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.traderListtList()
			},
			feehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.feepageSize = val
				this.feepage = 1
				this.traderFeeList()
			},
			feehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.feepage = val
				this.traderFeeList()
			},
			limithandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.limitpageSize = val
				this.limitpage = 1
				this.traderLimitList()
			},
			limithandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.limitpage = val
				this.traderLimitList()
			},
			addAccount(type, item) {
				this.dialogVisible = true
				this.dwloading = true
				this.type = type
				if (item) {
					this.item = item
					this.id = item.id;
					this.business_no = item.business_no;
					this.form.agent = item.agent;
					this.form.typeValue = Number(item.type) == 0?'':Number(item.type);
					this.form.name = item.name;
					this.form.md5_key = item.md5_key;
					this.form.fail_count = item.fail_count;
					this.form.username = item.username;
					this.form.business_name = item.business_name;
					this.status = item.status == 0 ? true : false;
					this.status_order = item.status_order == 0 ? true : false;
					this.is_can_trader_repay = item.is_can_trader_repay == 1 ? true : false;
					this.weight_type = item.weight_type.toString();
					this.repay_type = item.repay_type == 1 ? true : false;
					this.status_pay = item.status_pay == 0 ? true : false;
					this.status_payMatchType = item.pay_match_type == 2 ? true : false;
					// this.status_pay_api = item.status_pay_api == 0 ? true : false;
					this.is_collection = item.is_collection == 1 ? true : false;
					this.is_payment = item.is_payment == 1 ? true : false;
					this.money = item.money;
					this.money_uncertain = item.money_uncertain;
				}
				
				if (this.type === 'new') {
					this.title = '新增商户'
					this.submitText = this.$t('main.accountMenu.add')

				} else if (this.type === 'edt') {
					this.title = '编辑商户'
					this.submitText = '修 改'
				} else if (this.type === 'password') {
					this.title = '重置商户密码'
					this.submitText = '重 置'
				} else if (this.type === 'fee') {
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.commissionCollection') //'充值手续费'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.traderFeeNum = {}
					// this.getTraderFeeNum()
					this.submitText = '确 定'
					this.traderFeeList('fee')
					this.dwloading = false
				}else if (this.type === 'reFee') {
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.commissionPayment') //'代付手续费'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.traderFeeNum = {}
					this.submitText = '确 定'
					this.traderRepayFeeList()
					this.dwloading = false
				} else if (this.type === 'singleLimit') {
					this.dialogVisible = false
					this.title = '设置单笔限额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderLimitList()
				} else if (this.type === 'payPower') {
					this.dialogVisible = false
					this.title = '代收通道配置'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderPayList(item);
					this.dwloading = false
					// this.traderFeeList()
					// this.agentFeeList()
				} else if (this.type === 'repayPower') {
					this.dialogVisible = false
					this.title = '代付通道配置'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderRepayList(item);
					this.dwloading = false
				} else if (this.type === 'ratio') {
					this.dialogVisible = false
					this.title = '代收通道分流'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.setRratioList(item);
					this.dwloading = false
				} else if (this.type === 'repayRatio') {
					this.dialogVisible = false
					this.title = '代付通道分流'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.repayRatioList(item);
					this.dwloading = false
				} else if (this.type === 'payPassword') {
					this.title = '代付密码'
					// this.titleName = item.business_name+`  `+item.business_no
					this.submitText = '确 定'
				} else if (this.type === 'freeze') {
					this.title = '扣除余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getCurrency()
				} else if (this.type === 'unFreeze') {
					this.title = '调额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getCurrency()
				} else if (this.type === 'yufu') {
					this.title = '调预付'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getCurrency()
				} else if (this.type === 'clearUnBalance') {
					this.title = '销毁冻结余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getCurrency()
				} else if (this.type === 'inOutUnMoney') {
					this.title = '增减冻结余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getCurrency()
				} else if (this.type === 'whitelist') {
					this.dialogVisible = false
					this.title = '商户白名单'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.getTraderWhiteList()
					// this.$prompt('请输入谷歌验证码', '提示', {
					// 		  confirmButtonText: '确定',
					// 		  cancelButtonText: '取消',
					// 		}).then(({ value }) => {
					// 			this.getTraderWhiteList(value)
					// 		}).catch(() => {
					// 		  this.$message({
					// 			type: 'info',
					// 			message: '取消输入'
					// 		  });       
					// 		});
				} else if (this.type === 'traderFeeNum') {
					this.traderFeeNum = {}
					this.getTraderFeeNum()
					this.title = '商户单笔手续费'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				} else if (this.type === 'keepGold') {
					this.title = '余额留存上限'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}else if(this.type =='payMatchType'){
					this.title = '姓名匹配模式'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}else if(this.type =='bindBusinessCurrency'){
					this.title = '商户绑定币种'
					// this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.checkBusinessCurrencys(item);
				}else if (this.type === 'offlineFee') {
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.offlineDepositHandlingFee') // ' 线下入金手续费'
					this.submitText = '确 定'
					this.titleName = item.username
					this.agentOfflineFeeList()
					this.dwloading = false
				}

			},
			//手续费列表查询
			agentOfflineFeeList() {
				let data = {
					trader_id: this.id,
				}
				// this.dialogloading = true
				this.$api.currencyTypeFeeList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.offlineFeeTable = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.id = "";
				this.business_no = "";
				this.amount = "";
				this.form.name = "";
				this.form.md5_key = "";
				this.form.fail_count = 0;
				this.form.username = "";
				this.form.password = "";
				this.form.typeValue = '';
				this.passwordSure = "";
				this.form.agent = "";
				this.form.business_name = "";
				this.status = true;
				this.status_order = true;
				this.is_can_trader_repay = false;
				this.weight_type = "2";
				this.repay_type = true;
				this.status_pay = true;
				// this.status_pay_api = true;
				this.status_gold_limit = true;
				this.is_collection = false;
				this.is_payment = false;
				this.feeData = [];
				this.singleLimitData = [];
				this.money = '';
				this.money_uncertain = '';
				this.titleName = '';
				this.payWay = [];
				this.ratio = [];
				this.item = {};
				this.unmoneyAmount = ''
				this.unmoneyRadio = 'IN'
				this.fileWhiteList_login = ''
				this.fileWhiteList_repay = ''
				this.amountNote = ''
				this.amountForm.amount = ''
				this.amountForm.amountNote = ''
				this.amountForm.googleCode = ''
			},
			// 代付
			traderRepayFeeList(){
				this.traderRepayFeeLoading = true
				let data = {
					trader_id: this.item.id
				}
				this.$api.traderRepayFeeList(data).then(res => {
					if(res.status === 1){
						console.log(res.data.data)
						let data = res.data.data
						this.traderRepayFeeData = []
						this.traderRepayFeeData = res.data.data
						
					}
					
					
				})
			},
			//手续费列表查询
			traderFeeList(type) {
				this.traderFeeLoading = true
				let data = {
					trader_id: this.id,
					has_repay: type === 'setlimit'?1:0,
					page: this.feepage,
					size: this.feepageSize
				}
				// this.dialogloading = true
				this.dwloading = true
				console.log('dwloading', this.dwloading)
				this.$api.traderFeeList(data).then(res => {
					this.dialogloading = false
					this.dwloading = false
					if (res.status === 1) {

						if(type === 'fee'){
							let data = res.data.data
							this.traderFeeDataArr = []
							this.traderFeeDataArr = res.data.data
							// console.log(this.traderFeeDataArr)
							this.spanArr= []
							this.pos= ''
							// this.getSpanArr(this.traderFeeDataArr,'spanArr','pos')
						}else if(type === 'setlimit'){
							let data = res.data.data
							this.traderFeeDataArr = []
							data.map((item) =>{
								var arr = []
								for (let i in item.cur_list) {
									arr.push(item.cur_list[i]); //属性
								}
								if(item.cur_list.length === 0){
									this.traderFeeDataArr.push({
										id :item.pay_type,
										pay_type:item.pay_type,
										pay_type_name:item.pay_type_name,
										business_no:item.business_no,
									})
								}else{
									arr.map((val)=>{
										let current = {
											id :item.pay_type,
											pay_type:item.pay_type,
											pay_type_name:item.pay_type_name,
											business_no:item.business_no,
						
											max_gold:val.max_gold,
											min_gold:val.min_gold,
											cur_name:val.cur_name,
											cur_id:val.cur_id,
											agent_fee: 0,
											cur_type: val.cur_type,
											fee: val.fee,
											agent_fee:val.agent_fee,
											fee_num: val.fee_num,
											_id: val.id,
											relation_id: val.relation_id,
										}
										this.traderFeeDataArr.push(current)
									})
								}
							})
							// console.log(this.traderFeeDataArr)
							this.spanArr= []
							this.pos= ''
							this.getSpanArr(this.traderFeeDataArr,'spanArr','pos')
						}else{
							this.traderFeeData = res.data.data
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					this.traderFeeLoading = false	
				}).catch(error => {
					this.traderFeeLoading = false	
					this.dialogloading = false
				})

			},
			//手续费列表查询
			traderLimitList() {
				let data = {
					trader_id: this.id,
					page: this.limitpage,
					size: this.limitpageSize
				}

				// this.dialogloading = true
				this.$api.traderFeeList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.limittotal = res.data.total
						this.singleLimitData = res.data.data
						this.traderFeeList('setlimit')
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			getCurrency(){
				let data = {
					id: this.id,
				}
				this.$api.traderMoney(data).then(res => {
					this.currencyFreeZeList = res.data
				})
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					// if (isEmpty(this.form.agent, '请选择代理')) {
					// 	return
					// }
					if (isEmpty(this.form.username, '请输入商户名')) {
						return
					}
					if (isIncludeHanzi(this.form.username, '商户名不能包含汉字')) {
						return
					}
					if (isEmpty(this.form.business_name, '请输入商户名称')) {
						return
					}
					if (isEmpty(this.form.password, '请输入商户密码')) {
						return
					}
					if (isEmpty(this.form.typeValue, '请选择商户类型')) {
						return
					}
					if (this.form.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					let data = {
						note: this.form.name,
						agent: this.form.agent,
						trader_username: this.form.username,
						business_name: this.form.business_name,
						password: this.$md5(this.form.password),
						status: this.status ? 0 : 1,
						status_order: this.status_order ? 0 : 1,
						is_can_trader_repay: this.is_can_trader_repay ? 1 : 2,
						weight_type: this.weight_type,
						repay_type: this.repay_type ? 1 : 2,
						status_pay: this.status_pay ? 0 : 1,
						// status_pay_api: this.status_pay_api ? 0 : 1,
						status_gold_limit: this.status_gold_limit ? 0 : 1,
						is_collection: this.is_collection ? 1 : 2,
						is_payment: this.is_payment ? 1 : 2,
						type: this.form.typeValue,
					}
					this.dialogloading = true
					this.$api.addTrade(data).then(res => {
						// console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							// setTimeout(()=>{
							this.traderListtList()
							// },200)

						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'edt') {
					//编辑
					if (isEmpty(this.form.business_name, '请输入商家名称')) {
						return
					}
					let data = {
						trader_id: this.id,
						note: this.form.name,
						// agent: this.form.agent,						
						business_name: this.form.business_name,
						status: this.status ? 0 : 1,
						status_order: this.status_order ? 0 : 1,
						is_can_trader_repay: this.is_can_trader_repay ? 1 : 2,
						weight_type: this.weight_type,
						repay_type: this.repay_type ? 1 : 2,
						status_pay: this.status_pay ? 0 : 1,
						// status_pay_api: this.status_pay_api ? 0 : 1,
						status_gold_limit: this.status_gold_limit ? 0 : 1,
						is_collection: this.is_collection ? 1 : 2,
						is_payment: this.is_payment ? 1 : 2,
						type: this.form.typeValue,
					}
					this.dialogloading = true
					this.$api.editTrader(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.traderListtList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'payPassword') {
					this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
						confirmButtonText: this.$t('main.common.submit'),
						cancelButtonText: this.$t('main.common.cancel'),
						type: 'warning'
					}).then(() => {
						if (isEmpty(this.form.password, '代付密码不能为空')) {
							return
						}
						if (this.form.password != this.passwordSure) {
							this.$message({
								type: 'error',
								message: '两次输入密码不一致'
							})
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							password: this.$md5(this.form.password),
						}
						this.dialogloading = true
						this.$api.setTraderPayPassword(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: this.$t('main.common.successConfirm')
								})
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置代付密码`
						});
					});
				} else if (this.type === 'freeze' || this.type === 'unFreeze') {
					// 调额
					let data = {
						id: this.id,
						business_no: this.business_no,
						amount: this.amountForm.amount,
						note:this.amountForm.amountNote,
						googleCode:this.amountForm.googleCode,
					}
					this.dialogloading = true
					this.$api.setAmount(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.traderListtList()
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
					
				} else if (this.type === 'yufu') {
					// 调额
					let data = {
						id: this.id,
						business_no: this.business_no,
						amount: this.amountForm.amount,
						note:this.amountForm.amountNote,
						googleCode:this.amountForm.googleCode,
					}
					this.dialogloading = true
					this.$api.setRepaid(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.traderListtList()
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
					
				}else if (this.type === 'inOutUnMoney') {
					this.$prompt('请输入谷歌验证码', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							}).then(({ value }) => {
								this.inOutUnMoneyFun(value)
							}).catch(() => {
							  this.$message({
								type: 'info',
								message: '取消输入'
							  });       
							});
					
				} else if (this.type === 'clearUnBalance') {
					//新增商家
					this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
						confirmButtonText: this.$t('main.common.submit'),
						cancelButtonText: this.$t('main.common.cancel'),
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.amount, '余额只能是数字，最多输入两位小数')) {
							return
						}
						if(this.freezeCurrency === ''){
							this.$message({
							type: 'warning',
							message: `请选择币种`
						});
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							amount: this.amount,
							cur_id:this.freezeCurrency
						}
						this.dialogloading = true
						this.$api.clearTraderUncertainMoney(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消${this.type === 'freeze'?'扣除金额':'增加金额'}`
						});
					});
				} else if (this.type === 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

					for (let i = 0; i < this.traderFeeDataArr.length; i++) {
						if (this.traderFeeDataArr[i].fee === '' || (!pattern.test(this.traderFeeDataArr[i].fee) && this.traderFeeDataArr[i].fee !=0 && this.traderFeeDataArr[i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							break
						} else if (this.traderFeeDataArr[i].fee < this.traderFeeDataArr[i].agent_fee) {
							console.log(this.traderFeeDataArr)
							console.log(this.traderFeeDataArr[i])
							console.log(this.traderFeeDataArr[i].fee)
							console.log(this.traderFeeDataArr[i].agent_fee)
							errorString = "["+this.traderFeeDataArr[i].pay_type+"]" + this.traderFeeDataArr[i].pay_type_name + ': 商家手续费不能低于代理手续费!'
							break
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					// if (!isMoney(this.traderFeeNum.fee_num, '商家单笔手续费只能是数字，最多输入两位小数')) {
					// 	return
					// }
					// this.setTraderFeeNum()
					let feeData = this.traderFeeDataArr.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							'cur_id':item.cur_id,
							'min_gold':item.min_gold,
							'max_gold':item.max_gold,
						}
					})
					this.dwloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(feeData)
					}
					// console.log('feedata', data)
					this.$api.setTraderFee(data).then(res => {
						this.dwloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							
							this.traderFeeList('fee')
							
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dwloading = false
					})
				} 
				else if(this.type === 'reFee'){
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]|[0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
					for (let i = 0; i < this.traderRepayFeeData.length; i++) {
						if (this.traderRepayFeeData[i].fee === '' || (!pattern.test(this.traderRepayFeeData[i].fee))) {
							errorString = '手续费只能是数字，最多两位小数'
							break
						} else if (this.traderRepayFeeData[i].fee < this.traderRepayFeeData[i].agent_fee) {
							errorString = ' 商家手续费不能低于代理手续费!'
							break
						}
						if (this.traderRepayFeeData[i].fee_num === '' || (!pattern.test(this.traderRepayFeeData[i].fee_num)) ) {
							errorString = '单笔费用只能是数字，最多两位小数'
							break
						} else if (Number(this.traderRepayFeeData[i].fee_num) < Number(this.traderRepayFeeData[i].agent_fee_num)) {
							errorString = '单笔费用不能低于手续费!'
							break
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.traderRepayFeeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							'cur_id':item.cur_id,
							"fee_num": item.fee_num,
							"min_gold": item.min_gold,
							"max_gold": item.max_gold,
						}
					})
					let data = {
						trader_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.dwloading = true
					this.$api.setTraderRepayFee(data).then(res => {
						this.dwloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.traderRepayFeeList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						
					}).catch(error => {
						this.dwloading = false
					})
				}
				else if (this.type === 'singleLimit') {
					this.singleLimitData = this.traderFeeDataArr
					let errorString = ''
					var pattern = /^[0]|[0-9]*$/

					for (let i = 0; i < this.singleLimitData.length; i++) {
						if ( !pattern.test(this.singleLimitData[i].min_gold)) {
							errorString = '最小限额只能是数字'
							break;
						}
						if (Number(this.singleLimitData[i].min_gold) < 0) {
							errorString = '最小限额不能小于0'
							break;
						}
						if ( !pattern.test(this.singleLimitData[i].max_gold)) {
							errorString = '最大限额只能是数字'
							break;
						}
						if (Number(this.singleLimitData[i].max_gold) < 0) {
							errorString = '最大限额不能小于0'
							break;
						}
						if (Number(this.singleLimitData[i].max_gold) < Number(this.singleLimitData[i].min_gold)) {
							errorString = '最大限额不能小于最小限额'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let singleLimitData = this.singleLimitData.map(item => {
						return {
							"pay_type": item.pay_type,
							"min_gold": item.min_gold,
							"cur_id": item.cur_id,
							"max_gold": item.max_gold
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(singleLimitData)
					}
					// console.log('feedata', data)
					this.$api.setTraderGold(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							// this.close()
							this.traderFeeList('setlimit')
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'payPower') {
					let errorString=""
					let result = []
					for (let i = 0; i < this.newPayWay.length; i++) {
						let item = this.newPayWay[i]
						if(item.state && item.pay_type_state){
							let pay_type = {
								payway_type_id:item.id,
								pay_type:item.pay_type_id,
							};
							result.push(pay_type)

						}
					}
				
					let data = {
						data: JSON.stringify(result),
						trader_id: this.id,
						business_no: this.business_no,
					}
					this.dwloading = true
					this.$api.setTraderPayWay(data).then(res => {
						this.dwloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							this.traderPayList(this.item)
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dwloading = false
					})
					// console.log(data)

				} else if (this.type === 'repayPower') {
					let errorString=""
					let result = []
					console.log( this.newRepayWay)
					for (let i = 0; i < this.newRepayWay.length; i++) {
						let item = this.newRepayWay[i]
						if(item.state && item.pay_type_state){
							let pay_type = {
								payway_type_id:item.id,
								pay_type:item.pay_type_id,
							};
							result.push(pay_type)
						}
					}
				
					let data = {
						data: JSON.stringify(result),
						trader_id: this.id,
						business_no: this.business_no,
					}
					this.dwloading = true
					this.$api.setTraderRepayWay(data).then(res => {
						this.dwloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							this.traderRepayList(this.item)
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dwloading = false
					})
					// console.log(data)

				} else if (this.type === 'ratio') {
					// 数值判断
					console.log('ratio',this.ratio);
					let flag = false
					for(let i = 0;i<this.ratio.length;i++){
						let filterList = this.ratio.filter(item=>this.ratio[i].pay_type == item.pay_type)
						console.log('filterList',filterList);
						let sum = 0
						filterList.map(v=>{
							sum += v.ratio
						})
						if(sum != 100){
							flag = true
							break 
						}
						// if(sum >= 99.9 && sum <= 100){
						// }else{
						// 	flag = true
						// 	break 
						// }
					}
					if(flag){
						this.$message({
							type: 'error',
							message: '代收方式下的通道的比例之和必须等于100'
						})
					}else{
						
							let errorMsg = '';
							let data = {
								data: JSON.stringify(this.ratio),
								trader_id: this.id,
								business_no: this.business_no,
							}
							
							this.dwloading = true
							this.$api.setRatio(data).then(res => {
								this.dwloading = false
								if (res.status === 1) {
									this.$message({
										type: 'success',
										message: this.$t('main.common.successConfirm')
									})
									// this.close()
									this.setRratioList()
								} else {
									this.$message({
										type: 'error',
										message: res.msg
									})
								}
							}).catch(error => {
								this.dialogloading = false
							})
						
					}
				} else if (this.type === 'repayRatio') {
					// 数值判断
					
					let flag = false
					for(let i = 0;i<this.repayRatioData.length;i++){
						let filterList = this.repayRatioData.filter(item=>this.repayRatioData[i].pay_type == item.pay_type)
						console.log('filterList',filterList);
						let sum = 0
						filterList.map(v=>{
							sum += v.ratio
						})
						if(sum != 100){
							flag = true
							break 
						}
						// if(sum >= 99.9 && sum <= 100){
						// }else{
						// 	flag = true
						// 	break 
						// }
					}
					if(flag){
						this.$message({
							type: 'error',
							message: '代付方式下的通道的比例之和必须等于100'
						})
					}else{
					
							let errorMsg = '';
							let data = {
								data: JSON.stringify(this.repayRatioData),
								trader_id: this.id,
								business_no: this.business_no,
							}
							
							this.dwloading = true
							this.$api.setRepayRatio(data).then(res => {
								this.dwloading = false
								if (res.status === 1) {
									this.$message({
										type: 'success',
										message: this.$t('main.common.successConfirm')
									})
									// this.close()
									this.repayRatioList()
								} else {
									this.$message({
										type: 'error',
										message: res.msg
									})
								}
							}).catch(error => {
								this.dwloading = false
							})
						
					}
				} else if (this.type == 'whitelist') {
					this.$prompt('请输入谷歌验证码', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							}).then(({ value }) => {
								this.setTraderWhiteList(value)
							}).catch(() => {
							  this.$message({
								type: 'info',
								message: '取消输入'
							  });       
							});
					
				} else if (this.type === 'traderFeeNum') {
					if (!isMoney(this.traderFeeNum.fee_num, '商家单笔手续费只能是数字，最多输入两位小数')) {
						return
					}
					this.setTraderFeeNum()
				} else if (this.type === 'keepGold') {
					//新增商家
					this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
						confirmButtonText: this.$t('main.common.submit'),
						cancelButtonText: this.$t('main.common.cancel'),
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.item.trader_keep_gold, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							amount: this.item.trader_keep_gold
						}
						this.dialogloading = true
						this.$api.setTraderKeepGold(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置留存上限`
						});
					});
				} else if(this.type == 'payMatchType'){
					
					this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
						confirmButtonText: this.$t('main.common.submit'),
						cancelButtonText: this.$t('main.common.cancel'),
						type: 'warning'
					}).then(() => {
						let data = {
							id:this.item.id,
							type:this.status_payMatchType?2:1
						}
						this.dialogloading = true
						this.$api.setTraderPayMatchType(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置姓名匹配模式`
						});
					});
					// console.log(data)
					// status_pay: this.status_pay ? 0 : 1,
				} else if(this.type == 'bindBusinessCurrency'){
					
					this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
						confirmButtonText: this.$t('main.common.submit'),
						cancelButtonText: this.$t('main.common.cancel'),
						type: 'warning'
					}).then(() => {
						let data = {
							business_no:this.item.business_no,
							currencys:JSON.stringify(this.checkBoxTraderCurrency.traderCurrencys)
						}
						this.dialogloading = true
						this.$api.bindBusinessCurrency(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置姓名匹配模式`
						});
					});
					// console.log(data)
					// status_pay: this.status_pay ? 0 : 1,
				}else if (this.type === 'offlineFee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
					for (let i = 0; i < this.offlineFeeTable.length; i++) {
						if (this.offlineFeeTable[i].fee === '' || (!pattern.test(this.offlineFeeTable[i].fee) && this.offlineFeeTable[i].fee != 0 && this.offlineFeeTable[
								i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							// console.log("+++++++++",this.feeData[i])
							break;
						} else if (this.offlineFeeTable[i].fee < this.offlineFeeTable[i].agent_fee) {
							errorString = this.offlineFeeTable[i].name + ': 手续费范围不能低于最小值!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(this.offlineFeeTable)
					}
					this.dialogloading = true
					this.dwloading = true
					this.$api.currencyTypeSetFee(data).then(res => {
						this.dwloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							
							this.agentOfflineFeeList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
						this.dwloading = false
					}).catch(error => {
						this.dwloading = false
						this.dialogloading = false
					})
				}
			},
			//重置商家密码
			resPassword(item) {

				this.$prompt('请输入谷歌验证码', '提示', {
						  confirmButtonText: '确定',
						  cancelButtonText: '取消',
						}).then(({ value }) => {
							
							let data = {
								id: item.id,
								business_no: item.business_no,
								googleCode: value,
							}
							this.loading = true
							this.$api.setTraderPassword(data).then(res => {
								// console.log(res)
								this.loading = false
								if (res.status === 1) {
									this.$alert('新密码为:' + res.data, '重置密码成功', {
										confirmButtonText: '确定',
									});
								} else {
									this.$message({
										type: 'error',
										message: res.msg
									});
								}
							
							}).catch(error => {
								this.loading = false;
							})
							
						}).catch(() => {
						  this.$message({
							type: 'info',
							message: '取消输入'
						  });       
						});
			

			},
			traderToggle(item) {
				let status = item.status === 0 ? '冻结' : '启用'
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
						business_no: item.business_no
					}
					this.loading = true
					this.$api.traderToggle(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.traderListtList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${status}商家`
					});
				});

			},
			changeState(item) {
				let data = {
					id: item.id,
					business_no: item.business_no
				}
				this.loading = true
				this.$api.traderToggle(data).then(res => {
					this.loading = false
					// console.log(res)
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.traderListtList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}

				}).catch(error => {
					this.loading = false
				})
				

			},
			traderPayList(item) {
				let data = {
					trader_id: item.id,
					business_no: item.business_no
				}
				this.dialogloading = true;
				this.$api.traderPayList(data).then(res => {
					this.dialogloading = false;
					if (res.status === 1) {
						this.payWay = res.data
						
						this.newPayWay = []
						this.payWay.forEach((item, index)=>{
							var arr = []
							for (let i in item.pay_type_list) {
							    arr.push(item.pay_type_list[i]); //属性
							}
							if(item.pay_type_list.length === 0){
								this.newPayWay.push({
											id:item.id,
											name:item.name,
											state:item.state,
								})
							}else{
								arr.forEach((val, key)=>{
									let current = {
											id:item.id,
											name:item.name,
											state:item.state,
											
											pay_type_id:val.id,
											pay_type_name:val.name,
											pay_type_state:val.state,
											cur_list:val.cur_list,
									}
									this.newPayWay.push(current)
								})
							}
						})
						this.spanArr= []
						this.pos= ''
						this.getSpanArr(this.newPayWay,'spanArr','pos')
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.dialogloading = false
				})

			},
			traderRepayList(item) { // 代付通道查询.
				let data = {
					trader_id: item.id,
					business_no: item.business_no
				}
				this.dialogloading = true;
				this.$api.traderRepayList(data).then(res => {
					this.dialogloading = false;
					if (res.status === 1) {
						this.payWay = res.data
						this.newRepayWay = []
						this.payWay.forEach((item, index)=>{
							var arr = []
							for (let i in item.pay_type_list) {
							    arr.push(item.pay_type_list[i]); //属性
							}
							if(item.pay_type_list.length === 0){
								this.newRepayWay.push({
											id:item.id,
											name:item.name,
											state:item.state,
								})
							}else{
								arr.forEach((val, key)=>{
									let current = {
											id:item.id,
											name:item.name,
											state:item.state,
											
											pay_type_id:val.id,
											pay_type_name:val.name,
											pay_type_state:val.state,
											cur_list:val.cur_list,
									}
									this.newRepayWay.push(current)
								})
							}
						})
						this.spanArr= []
						this.pos= ''
						this.getSpanArr(this.newRepayWay,'spanArr','pos')
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.dialogloading = false
				})

			},

			//通道选项  全选 
			payTypeChange(item,type,state) {
				if(item.pay_type_state){
					item.state = true
					item.pay_type_state = true
					this.newPayWay.forEach(subitem => {
						if(subitem.id === item.id){
							subitem.state = true
						}
					})
				}
			},
			//支付方式选项
			payWayChange(item) {
				let noAll = true;
				item.pay_type_list.forEach((subitem, subindex) => {
					if (subitem.state) {
						noAll = false;
					}
				})
				if (noAll) {
					item.state = false
				} else {
					item.state = true
				}
			},
			setRratioList(item) {
				let data = {
					trader_id: this.id,
					business_no: this.business_no,
				}
				this.$api.setRratioList(data).then(res => {
					if (res.status == 1) {
						this.ratio = res.data
						this.spanArr = []
						this.pos = ''
						this.spanArr2= []
						this.pos2= ''
						this.getSpanArrNew(this.ratio)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			repayRatioList(item) {
				let data = {
					trader_id: this.id,
					business_no: this.business_no,
				}
				this.$api.repayRatioList(data).then(res => {
					if (res.status == 1) {
						this.repayRatioData = res.data
						this.spanArr = []
						this.pos = ''
						this.spanArr2= []
						this.pos2= ''
						this.getSpanArrNew(this.repayRatioData)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			getSpanArrNew(data) {
				for (var i = 0; i < data.length; i++) {
					let payTypeS = false;
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0;
						this.spanArr2.push(1);
						this.pos2 = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
							if(data[i].cur_id === data[i-1].cur_id){
								this.spanArr2[this.pos2] += 1;
								this.spanArr2.push(0);
							}else{
								this.spanArr2.push(1);
								this.pos2 = i;
							}
						} else {
							this.spanArr.push(1);
							this.pos = i;
							
							this.spanArr2.push(1);
							this.pos2 = i;
						}
					}
					// console.log(this.spanArr2)
				}
			},
			getSpanArr(data,key,pos) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this[key].push(1);
						this[pos] = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							
							this[key][this[pos]] += 1;
							this[key].push(0);
							
						} else {
							this[key].push(1);
							this[pos] = i;
						}
					}
				}
			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			//重置谷歌验证码
			setMemberGoogleKey(item) {
				
				this.$prompt('请输入谷歌验证码', '提示', {
						  confirmButtonText: '确定',
						  cancelButtonText: '取消',
						}).then(({ value }) => {
						
							// 限制充值
							this.loading = true;
							let data = {
								id: item.id,
								googleCode: value,
							}
							this.$api.setMemberGoogleKey(data).then(res => {
								this.loading = false;
								if (res.status === 1) {
									this.$message({
										type: 'success',
										message: res.msg
									})
								} else {
									this.$message({
										type: 'error',
										message: res.msg
									})
								}
							}).catch(error => {
								this.loading = false;
							})
							
						}).catch(() => {
						  this.$message({
							type: 'info',
							message: '取消输入'
						  });       
						});
			},

			//日志
			showLog(item) {
				this.logdialog = true
				this.item = item
				this.rechargeLogList();
			},
			logclose() {
				this.logdialog = false
				this.logName = 'recharge'
				this.rechargeLog = []
				this.deductionLog = []
				this.feeLog = []
				this.item = {}
			},
			getlogData(val) {
				// console.log('ppppppppp',val)
				if (val.name == 'recharge') {
					if (this.rechargeLog.length == 0) {
						this.rechargeLogList();
					}
				}
				if (val.name == "deduction") {
					if (this.deductionLog.length == 0) {
						this.deductionLogList();
					}
				}
				if (val.name == "fee") {
					if (this.feeLog.length == 0) {
						this.feeLogList();
					}
				}
			},
			//冻结|解冻
			rechargeLogList() {
				let data = {
					page: this.reLogpage,
					size: this.reLogpageSize,
					id: this.item.id,
					type: 1,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res => {
					this.logLoding = false;
					if (res.status == 1) {
						this.rechargeLog = res.data.data;
						this.reLogtotal = res.data.total
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.logLoding = false;
				})
			},
			reLoghandleSizeChange(val) {
				this.reLogpageSize = val
				this.reLogpage = 1
				this.rechargeLogList()
			},
			reLoghandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.reLogpage = val
				this.rechargeLogList()
			},
			//限额
			deductionLogList() {
				let data = {
					page: this.dedLogpage,
					size: this.dedLogpageSize,
					id: this.item.id,
					type: 2,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res => {
					this.logLoding = false;
					if (res.status == 1) {
						this.deductionLog = res.data.data;
						this.dedLogtotal = res.data.total
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.logLoding = false;
				})
			},
			dedLoghandleSizeChange(val) {
				this.dedLogpageSize = val
				this.dedLogpage = 1
				this.deductionLogList()
			},
			dedLoghandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.dedLogpage = val
				this.deductionLogList()
			},
			//手续费
			feeLogList() {
				let data = {
					page: this.feeLogpage,
					size: this.feeLogpageSize,
					id: this.item.id,
					type: 3,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res => {
					this.logLoding = false;
					if (res.status == 1) {
						this.feeLog = res.data.data;
						this.feeLogtotal = res.data.total
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.logLoding = false;
				})
			},
			feeLoghandleSizeChange(val) {
				this.feeLogpageSize = val
				this.feeLogpage = 1
				this.feeLogList()
			},
			feeLoghandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.feeLogpage = val
				this.feeLogList()
			},
			//获取商家单笔手续费
			getTraderFeeNum() {
				let data = {
					trader_id: this.item.id,
				}
				this.$api.getTraderFeeNum(data).then(res => {
					if (res.status == 1) {
						this.traderFeeNum = res.data
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			setTraderFeeNum() {
				let data = {
					trader_id: this.item.id,
					fee_num: this.traderFeeNum.fee_num
				}
				this.dialogloading = true;
				this.$api.setTraderFeeNum(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.close()
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//手续费列表查询
			agentFeeList() {
				let data = {
					agent_id: this.item.agent_id,
					page: this.afeepage,
					size: this.afeepageSize
				}
				// this.dialogloading = true
				this.$api.agentFeeList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.afeeData = res.data.data
						this.afeetotal = res.data.total
						this.newAgentFeeData = res.data.data
						// this.spanArrNew= []
						// this.posNew= ''
						// this.getSpanArr(this.newAgentFeeData,'spanArrNew','posNew')
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//选择搬砖工
			agentChang(value) {
				console.log(value)
				// this.carder_id = value
				// this.getData()
				// console.log('this.val', this.carder_id)
			},
			handleUpdate (value) {
			    //先把值赋为原来的值，保证swich状态不变
			    this.itemValue = value == '100'?'0':'100'
			    var text = ""
			    if(value == '100'){
			        text = "是否确认打开"
			    }else{
			        text = "是否确认关闭"
			    }
			    this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
			    	confirmButtonText: this.$t('main.common.submit'),
			    	cancelButtonText: this.$t('main.common.cancel'),
			    	type: 'warning'
			    }).then(()=>{
			       //确认，将改变后的状态值赋给绑定的itemValue
			       this.itemValue = value
			    }).catch(()=>{
			       //取消，将对立状态值赋给itemValue
			       this.itemValue = value == '100'?'0':'100'
			    })
			},
			//商户已绑定币种查询.
			checkBusinessCurrencys() {
				let data = {
					business_no: this.item.business_no
				}
				// this.dialogloading = true
				this.$api.businessCurrencys(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.checkBoxTraderCurrency.checkedCurs = []
						this.checkBoxTraderCurrency.traderCurrencys = res.data
						let cur_data = res.data
						for(let k = 0;k<cur_data.length;k++){
							if(cur_data[k].is_bind === 1){
								this.checkBoxTraderCurrency.checkedCurs.push(cur_data[k].id)
							}
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
		    handleCheckCurrencyAllChange(val) {
				this.checkBoxTraderCurrency.checkedCurs = val ? this.checkBoxTraderCurrency.traderCurrencys : [];
				this.checkBoxTraderCurrency.isIndeterminate = false;
		    },
		    handleCheckedCurrencyChange(value) {
				let checkedCount = value.length;
				this.checkBoxTraderCurrency.checkAll = checkedCount === this.checkBoxTraderCurrency.traderCurrencys.length;
				this.checkBoxTraderCurrency.isIndeterminate = checkedCount > 0 && checkedCount < this.checkBoxTraderCurrency.traderCurrencys.length;
				
				let tcs = this.checkBoxTraderCurrency.traderCurrencys
				for(let k = 0;k<tcs.length;k++){
					tcs[k].is_bind = 0
					for(let j = 0;j<value.length;j++){
						if(tcs[k].id === value[j]){
							tcs[k].is_bind = 1;
						}
					}
				}
				
				this.checkBoxTraderCurrency.traderCurrencys = tcs
		    },
			getSpanArr(data,key,pos) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this[key].push(1);
						this[pos] = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].id === data[i - 1].id) {
							
							this[key][this[pos]] += 1;
							this[key].push(0);
							
						} else {
							this[key].push(1);
							this[pos] = i;
						}
					}
					// console.log(this.spanArr2)
				}
			},
			objectSpanMethod({row,column,rowIndex,columnIndex}) {
				// console.log(this.spanArr)
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			objectSpanMethodNewRatio({row,column,rowIndex,columnIndex}){
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
				// if(columnIndex === 1){
				// 	const _row2 = this.spanArr2[rowIndex];
				// 	const _col2 = _row2 > 0 ? 1 : 0;
				// 	// console.log(`rowspan:${_row2} colspan:${_col2}`)
				// 	return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
				// 		rowspan: _row2,
				// 		colspan: _col2
				// 	}
				// }
			},
			objectSpanMethodNew({row,column,rowIndex,columnIndex}) {
				// console.log(this.spanArr)
				if (columnIndex === 0) {
					const _row = this.spanArrNew[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			openDrawer(item){ // 打开抽屉.
				this.dwdialog = true
				this.dwitem = item
				this.activeIndex = 'fee'
				if(this.activeIndex == 'fee'){
					this.addAccount('fee', item)
				}
			},
			handleClose(done) {
				console.log('handleClose 触发了')
			    this.close()
				 //  if (this.dwloading) {
					// return;
				 //  }
				done()
			  
			},
			  handleSelect(key, keyPath) {
				  
				this.traderFeeDataArr = []
				this.traderRepayFeeData = []
				this.offlineFeeTable = []
				this.newRepayWay = []
				this.newPayWay = []
				this.ratio = []
				this.repayRatioData = []
				
				this.activeIndex = key
				this.addAccount(key, this.dwitem)
				
			  }
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
				})
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}

	/* 滚动条宽度 */
	::-webkit-scrollbar {
		width: 0px;
	}

	table tbody {
		display: block;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		border-bottom: solid 1px #ddd;
	}

	table tbody tr:last-child td {
		border-bottom: none;
	}

	table thead tr,
	table tbody tr,
	table tfoot tr {
		box-sizing: border-box;
		table-layout: fixed;
		display: table;
		width: 100%;
	}

	table td {
		word-break: break-all;
	}

	.maxwidth {
		width: 330px;
	}

	.widthshangjia {
		width: 183px;
	}

	.widthfont4 {
		width: 45px;
	}

	.minwidth {
		width: 65px;
	}

	.dataTable {
		width: 100%;
	}
   .moneyFontSize{
	   font-size: 16px;
   }
   .usdtCss{
	   color: #270082;
	   font-size: xx-small;
   }
   .width150{
	   	width: 170px;
   }
   .demo-drawer__content{
	   margin: 10px 30px 10px;
   }
   
   .el-input {
	   // margin-left: 10px;
    //    width: 60%;
    }
   .el-menu-demo{
	   margin-bottom: 40px;
   }
	.yctable{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.yctable:hover {
	  overflow: visible; /* 鼠标悬停时显示全部内容 */
	  white-space: normal; /* 允许文字换行 */
	  text-overflow: clip; /* 移除省略号 */
	}
	
	/* 确保确认按钮始终在底部 */
	.drawer-footer {
	  position: fixed;
	  bottom: 0;
	  width: 100%;
	  padding: 10px;
	  background-color: #fff;
	  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
	  text-align: center;
	}
   
</style>
